var processInclude = require('base/util');

(async () => {
    try {
        processInclude(require('./product/variationAttributes'));
        processInclude(require('./product/proSection'));
        processInclude(require('./components/footer'));
        processInclude(require('./components/menu'));
        processInclude(require('./components/utilities'));
        processInclude(require('./components/search'));
        processInclude(require('./components/formFields'));
        processInclude(require('./components/sticky'));
        processInclude(require('./components/countrySelector'));
        processInclude(require('./gtm/gtmClick.js'));
        processInclude(require('./miniCart'));
        processInclude(require('./components/countDown'));
        processInclude(require('./owlCarouselADA'));
        processInclude(require('./footnoteADA'));
        processInclude(require('./bambuser'));
        require('./components/promo-banner');
        require('./components/inputFile');
        require('./lib/slick-slider-config');
        require('./lib/froogaloop2.min');
        require('./lib/tablistADA');
        require('./components/animationBanner');
    } catch (err) {
        console.log(err);
    }
})();


