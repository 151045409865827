$(document).ready(function() {
    var windowSize = $(window).width();
    var bannerExists = $('.navbar-promo').find('.navbar-promo-content')[0];
    var probannerExists = $('.ico-pro-mobile')[0];
    var topBannerAnnimation = $("#menus-container .top-animation-banner");
    var topBannerHeight = topBannerAnnimation.height();
    var headerHeight = $('.menu-bottom-container').height();
    var alertCreted =  $('.create-alert-wrapper .alert-product-stock-btn');

    if (windowSize > 960) {
        if(bannerExists === undefined) {
            $('.navbar-promo-grey').css({ 'background-color' : '#4A4A4A', 'display' : 'block' }); 
            $('.country-selector-section').css('position','static');
            $('.client_service').css('display','block');
            $('.promo-slot').css('display','none');
        }
    } else {
        // Mobile
        if (bannerExists !== undefined) {
            $('.alert-product-stock-btn').css('top', '59px').hide();
        } else if (probannerExists !== undefined) {
            $('.alert-product-stock-btn').css('top', '88px').show();
        }else {
            if (topBannerAnnimation.length) {
                alertCreted.css('top', headerHeight + topBannerHeight + 'px');
            } else {
                alertCreted.css('top', headerHeight + 'px');
            }
        }
    }
});
