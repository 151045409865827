'use strict';

function handleADASlides() {
    $('.owl-carousel .owl-item')
        .attr('aria-hidden', 'true')
        .find('a, button, input, select, textarea')
        .attr('tabindex', '-1');

    $('.owl-carousel .owl-item.active')
        .attr('aria-hidden', 'false')
        .find('a, button, input, select, textarea')
        .attr('tabindex', '0');
}

$('.owl-carousel').on('initialized.owl.carousel', function () {
    handleADASlides();
    setTimeout(() => {
        $('.owl-prev, .owl-next').on('click', function () {
            handleADASlides();
        });
    }, 500);
});
