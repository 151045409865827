function setProfileImageAltText() {
    $('.pro-informations').each(function () {
        var proName = $(this).find('.pro-name');
        var proImage = $(this).find('.pro-image');
        var proSubname = $(this).find('.pro-subname a');

        if (proName.length > 0 && proImage.length > 0) {
            var proNameText = proName.text().trim();
            proImage.attr('alt', 'Profile picture of ' + proNameText);
        }

        if (proSubname.length > 0 && proName.length > 0) {
            var proSubnameText = proName.text().trim();
            proSubname.attr(
                'aria-label',
                'This action will open the Instagram page of ' + proSubnameText
            );
        }
    });

    $('.pro-section-image').attr('alt', '');
}

setProfileImageAltText();
