'use strict';

const { trapFocus } = require('./util');

$(document).ready(function () {
    var $navsubbuttonlink = $('.nav-sub-button-link');
    var $navbutton = $('.nav-button');
    var $navsubbutton = $('.nav-sub-button');
    var $navbuttonmore = $('.nav-button-more');
    var $dropdownmenu = $('.dropdown-menu');
    var $dropdownPCIS = $('.dropdownPCIS');
    var $dropdownPCISBtn = $('#sorted-menu-category .dropdownPCIS button, #navbarSupportedContent .dropdownPCIS button');
    var $dropdownsubmenu = $('.dropdown-submenu');
    var $dropdownPCISsub = $('.dropdownPCISsub');

    var myTimeOut;

    var ul = document.getElementById("top-banner-text");
    if (ul) {
        messageLoop(); // eslint-disable-line no-undef
    }

    $('.banner-control-pause').click( function () {
        var $paused = $(".banner-control-pause").hasClass('paused');

        if(!($paused)){
            clearTimeout(myTimeOut);
            $(".banner-control-pause").addClass('paused');
            $(".banner-control-pause").find('.sr-only.paused').show();
            $(".banner-control-pause").find('.sr-only.play').hide();
        }else{
            $(".banner-control-pause").find('.sr-only.paused').hide();
            $(".banner-control-pause").find('.sr-only.play').show();
            $(".banner-control-pause").removeClass('paused');
            messageLoop(); // eslint-disable-line no-undef
        }
    });

    function OpenSubMenuPanel(i) {
        $dropdownmenu.hide().removeClass('active');
        $dropdownPCIS.removeClass('active');
        $(i).closest('.dropdownPCIS').addClass('active');
        $(i).siblings('.dropdown-menu').slideDown();
        $(i).siblings('.dropdown-menu').addClass('show active');
        $(i).parent().attr('aria-expanded', 'true');
    }
    function CloseSubMenuPanel(i) {
        $(i).closest('.dropdownPCIS').removeClass('active');
        $(i).siblings('.dropdown-menu').hide();
        $(i).siblings('.dropdown-menu').removeClass('active');
        $(i).parent().attr('aria-expanded', 'false');
    }

    function OpenSubCategoryMenuPanel(i) {
        if(i.closest('.dropdown-menu-2').length > 0){
            $('.dropdown-menu-2, .dropdown-menu-3').hide().removeClass('active').removeClass('show');
            $('.dropdownPCISsub').removeClass('active');
            i.closest('.dropdown-menu-2').show().addClass('active');
            i.closest('.dropdown-menu-2').addClass('show active');
            i.closest('.nav-item').children('.dropdown-menu').animate({width: 'toggle'});
            i.closest('.nav-item').children('.dropdown-menu').addClass('show active');
        } else {
            $dropdownsubmenu.hide().removeClass('active');
            $dropdownPCISsub.removeClass('active');
            i.closest('.nav-item').children('.dropdown-menu').animate({width: 'toggle'});
            i.closest('.nav-item').children('.dropdown-menu').addClass('show active');
        }
        $(i).closest('.dropdownPCISsub').addClass('active');
        $(i).attr('aria-expanded', 'true').addClass('active');
    }

    $('.nav-button, .nav-button-mobile').each(function () {
        var target = '#' + $(this).data('target');
        $(this).attr('data-target', target);
    });


    $('.nav-button-link, .nav-button, .nav-sub-button-link, .nav-sub-button').on('blur', function(){
        $(this).removeClass('focus-visible')
    }).on('focus', function(){
        $(this).addClass('focus-visible')
    })
    $('#nav-mobile-container a, #nav-mobile-container button, .nav-button-link, .nav-button, .collapsible-menu-md .dropdown-item a, .dropdown-menu.menu-more .nav-item a, .collapsible-menu-md .header-bloc-image a').keydown(function(e) {
        if (e.keyCode === 27) {
            var parentMenuMobile = $('#menu-nav-mobile.show');
            if($(this).closest('.dropdown-menu').hasClass('active') || $(this).closest('.nav-dropdown-menu').hasClass('active')){
                $navbutton.attr('aria-expanded', 'false');
                CloseSubMenuPanel($(this));
                CloseSubMenuPanel($navbuttonmore);
                $('body').removeClass('mouse-navigation');
            }
            if(parentMenuMobile.length > 0){
                parentMenuMobile.closest('.right-menu-list-item').find('.mobile-menu-button').focus();
                parentMenuMobile.removeClass('show').find('.nav-subitem').attr('aria-expanded','false');
                setTimeout(
                    function(){
                        $('body').removeClass('mouse-navigation');
                    }
                    ,200)
                
            }
        }
    });
    $('.collapsible-menu-md .dropdown-item:first-child a, .dropdown-menu.menu-more .nav-item:first-child a').keydown(function(event) {
        if (event.shiftKey && event.keyCode == 9) {
            var closestbutton = $(this).closest('.nav-item').find('.titlebutton');
            CloseSubMenuPanel(closestbutton);
            CloseSubMenuPanel($navbuttonmore);
            closestbutton.removeClass('focus-visible');
        }
    });

    $('.collapsible-menu-md .header-bloc-image a:last-of-type, .menu-more .nav-item-more:last-child a').keydown(function(event) {
        if (!event.shiftKey && event.keyCode == 9) {
            var closestbutton = $(this).closest('.nav-item').find('.titlebutton');
            CloseSubMenuPanel(closestbutton);
            CloseSubMenuPanel($navbuttonmore);
            closestbutton.removeClass('focus-visible');
        }
    });

    $navbutton.on({
        click: function () {
            $(this).addClass('tab-navigation');
            if($(this).hasClass('focus-visible')){
                $navbutton.attr('aria-expanded', 'false');
                if (!($(this).siblings('.dropdown-menu').hasClass('active'))) {
                    OpenSubMenuPanel($(this))
                    $('body').removeClass('mouse-navigation');
                } else {
                    if ($(this).data('href')) {
                        window.location.href = $(this).data('href');
                    } else {
                        CloseSubMenuPanel($(this));
                    }
                    $('body').removeClass('mouse-navigation');
                }
            }
        }
    });

    $navsubbuttonlink.on({
        click: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var url = $(this).attr('href');
            ($(this).attr('id') !== 'header-menu-MORE') ? window.location.href = url : '';
        },
        mouseenter: function () {
            $navbutton.each(function(){
                $(this).attr('aria-expanded', 'false');
            });
            OpenSubCategoryMenuPanel($(this));
            $(this).removeAttr('aria-expanded');
            $(this).siblings('.nav-button').attr('aria-expanded', 'true');
            $(this).parents('.dropdown-item').addClass('showing-sub').siblings('.dropdown-item').removeClass('showing-sub');
        }
    });
    $navsubbutton.on({
        click: function () {
            $(this).addClass('tab-navigation');
            if($(this).hasClass('focus-visible')){
                if (!($(this).siblings('.dropdown-menu').hasClass('active'))) {
                    $('body').removeClass('mouse-navigation');
                } else {
                    $('body').removeClass('mouse-navigation');
                }
            }
        }
    });

    $navbuttonmore.on({
        click: function () {
            $('#search-desktop-area').removeClass('show');
            $(this).addClass('tab-navigation');
            if(!($(this).closest('.nav-item').children('.dropdown-menu').hasClass('active'))){
                OpenSubMenuPanel($(this));
                $('body').removeClass('mouse-navigation');
            }else{
                CloseSubMenuPanel($(this));
                $('body').removeClass('mouse-navigation');
            }
        }
    });


    $dropdownPCISBtn.on({
        click: function () {
            if ($('#sorted-menu-category').length > 0) {
                // New header
                $('.dropdownPCIS').removeClass('active tab-navigation');
            } else {
                // Old header
                $('.dropdownPCIS button').removeClass('active tab-navigation');
            }
            
            $('#search-desktop-area').removeClass('show');
            
            if ($(this).hasClass('nav-button-more')) {
                if ($('.dropdownPCIS#menu-more').hasClass('tab-navigation')) {
                    $('.dropdownPCIS#menu-more').removeClass('show-menu tab-navigation');
                    CloseSubMenuPanel($(this));  
                } else {
                    if ($('#sorted-menu-category').length > 0) {
                        // New header
                        $('.dropdownPCIS#menu-more').addClass('show-menu tab-navigation');
                    } else {
                        // Old header
                        $(this).addClass('tab-navigation');
                    }
                    
                }
            } else {
                if ($('#sorted-menu-category').length > 0) {
                    // New header
                    $(this).parent().addClass('tab-navigation');
                } else {
                    // Old header
                    $(this).addClass('tab-navigation');
                }
                $('.dropdownPCIS#menu-more').removeClass('show-menu');
                if(!($(this).siblings('.dropdown-menu').hasClass('active'))){
                    OpenSubMenuPanel($(this));
                    $('body').removeClass('mouse-navigation');
                }else {
                    if ($(this).data('href')) {
                        window.location.href = $(this).data('href');
                    } else {
                        CloseSubMenuPanel($(this).parent());
                    }
                    $('body').removeClass('mouse-navigation');
                }
            }
        }
    });

    $('.nav-button-mobile').on('click', function(e) {
        if (!$(this).hasClass('new-country-selector-first') && !$(this).hasClass('new-country-selector-second')) {
            e.preventDefault();
            e.stopPropagation();
            $(this).attr('aria-expanded', 'false');

            $('.nav-item').find('.toggler-icon').removeClass('icon-minus').addClass('icon-PLUS');

            if (!$(this).siblings('.dropdown-menu').hasClass('active')) {
                $(this).closest('.nav-item').find('.toggler-icon').toggleClass('icon-minus icon-PLUS');
                $dropdownmenu.hide().removeClass('active');
                $dropdownPCIS.removeClass('active');
                $(this).closest('.dropdownPCIS').addClass('active');
                $(this).closest('.nav-item').children('.dropdown-menu').slideDown();
                $(this).closest('.nav-item').children('.dropdown-menu').addClass('active');
                $(this).closest('.nav-item').children('.dropdown-menu').toggleClass('fade-in-right');
                $(this).attr('aria-expanded', 'false');
                $(this).attr('aria-expanded', 'true');
            } else {
                $dropdownmenu.removeClass('active');
                $(this).closest('.nav-item').children('.dropdown-menu').hide();
                $(this).closest('.nav-item').children('.dropdown-menu').removeClass('active');
                $(this).closest('.nav-item').children('.dropdown-menu').removeClass('fade-in-right');
                $(this).closest('.nav-item').find('.dropdownPCIS').removeClass('active');
                $(this).closest('.dropdownPCIS').removeClass('active');
            }
            e.stopPropagation();
        } else {
            if ($(this).hasClass('new-country-selector-first')) {
                if (!$('.dropdown-menu.first-block').hasClass('active')) {
                    $('.dropdown-menu.first-block').slideDown();
                    $('.dropdown-menu.first-block').addClass('active');
                    $('.first-icon-plus').toggleClass('icon-minus icon-PLUS');
                } else {
                    $('.dropdown-menu.first-block').slideUp();
                    $('.dropdown-menu.first-block').removeClass('active');
                    $('.first-icon-plus').toggleClass('icon-minus icon-PLUS');
                }
            } else {
                if (!$(this).closest('.nav-item').children('.dropdown-menu.second-block').hasClass("active")) {
                    $('.nav-item').find('.toggler-icon.second-icon-plus').removeClass('icon-minus').addClass('icon-PLUS');
                    $('.dropdown-menu.second-block').removeClass('active');
                    $('.dropdown-menu.second-block').slideUp();
                    $(this).closest('.nav-item').children('.dropdown-menu.second-block').slideDown();
                    $(this).closest('.nav-item').children('.dropdown-menu.second-block').addClass('active');
                    $(this).closest('.nav-item').find('.toggler-icon.second-icon-plus').toggleClass('icon-minus icon-PLUS');
                } else {
                    $(this).closest('.nav-item').children('.dropdown-menu.second-block').slideUp();
                    $(this).closest('.nav-item').children('.dropdown-menu.second-block').removeClass('active');
                    $(this).closest('.nav-item').find('.toggler-icon.second-icon-plus').toggleClass('icon-minus icon-PLUS');
                }
            }
            e.stopPropagation();
        }
    });

    if (($(window).width() > 992)) {
        $('#register').removeAttr('role');
        $('#register').removeAttr('aria-labelledby');
        $('#login').removeAttr('role');
        $('#login').removeAttr('aria-labelledby');
    }

    if($('.login-all-page').length > 0){
        var loginTab = $('#loginTab');
        var registerTab = $('#registerTab');
        loginTab.on('click', function(){
            loginTab.addClass('active').focus();
            registerTab.removeClass('active').focusout();
            $('#login').addClass('active');
            $('#register').removeClass('active');
        });
        registerTab.on('click', function(){
            registerTab.addClass('active').focus();
            loginTab.removeClass('active').focusout();
            $('#login').removeClass('active');
            $('#register').addClass('active');
        })
        $(document).bind('keydown',function(e){
            var key  = e.keyCode;
            if(key == 37){
                if (!(loginTab.hasClass('active'))) {
                    loginTab.click().attr('aria-selected', true).attr('tabindex', 0).focus();
                    registerTab.attr('aria-selected', false).attr('tabindex', -1);
                }
            }else if(key == 39){
                if (!(registerTab.hasClass('active'))) {
                    registerTab.click().attr('aria-selected', true).attr('tabindex', 0).focus();
                    loginTab.attr('aria-selected', false).attr('tabindex', -1);
                }
            }
        });
    }
});

$('.js-hover-handler').hover(function() {
    $('.list-countries').toggle();
    $('.text-country-selector').toggle();
    var widthCountries = $('.list-countries').width();
    var widthPromo = $('.navbar-promo-content').width();
    var widthPromoBar = $('.promo-slot.col-12').width();

    if ((widthPromoBar-widthPromo)/2 < widthCountries+10) {
        $('.navbar-promo-content').addClass("navbar-promo-content-country-text");
    } else {
        $('.navbar-promo-content').removeClass("navbar-promo-content-country-text");
    }
});

$('.js-nav-link-hidden-toggle').on('click', function () {
    var hiddenToggleButton = $(this);
    var isExpanded = hiddenToggleButton.attr('aria-expanded') === 'false';
    hiddenToggleButton.attr('aria-expanded', isExpanded ? 'true' : 'false');
    
    var siblingButton = hiddenToggleButton.siblings('.dropdown-toggle');
    if (!isExpanded) {
        siblingButton.trigger('mouseleave');
    } else {
        siblingButton.trigger('mouseenter');
        var dropdownMenu = $(this).siblings('.dropdown-menu');
        var firstFocusableElement = dropdownMenu
            .find('.SeeAllcategory')
            .first();
        firstFocusableElement.focus();
        trapFocus(dropdownMenu[0]);
    }
});

$('.js-nav-link-hidden-toggle, .dropdown-toggle, .SeeAllcategory').on('keydown', function (event) {
    var key = event.key;
    var hiddenToggleButton = $(this);
    var siblingButton = hiddenToggleButton.siblings('.dropdown-toggle');
    var activeSubmenu = hiddenToggleButton.siblings('.dropdown-submenu.active');

    if (key === 'Escape' && activeSubmenu.length > 0) {
        activeSubmenu.removeClass('active');
        siblingButton.trigger('mouseleave');
        return;
    }

    if (key === 'Escape') {
        hiddenToggleButton.attr('aria-expanded', 'false');
        siblingButton.trigger('mouseleave');
    }
});

$(document).on('keydown', '.dropdown-submenu.active .productLinkto', function (event) {
    var key = event.key;

    if (key === 'Escape') {
        $('.dropdown-menu-2').hide();
    }
});

$(document).on('click', '.mobile-menu-button-close:not(.icon-return-back)', function () {
    $('.mobile-menu-button').trigger('click');
    $('main').removeClass('new-menu-expanded');
})

$(document).on('click', '.mobile-menu-button', function () {
    if ($('.page-designer-enabled').length) {
        $('main').addClass('new-menu-expanded');
    }
    $('.mobile-search-area').removeClass('show');
    $(this).attr('aria-expanded') === 'true' ? $('#alert-product-stock').hide() : $('#alert-product-stock').show();
    if ($('.navbar-promo:visible').length == 0) {
        if ($('#menu-nav-mobile:visible').length == 0) {
            setTimeout(function(){ 
                $("#menu-nav-mobile").addClass("show");
                $('.menu-mobile').addClass("menu-display");
                $("#menu-nav-mobile").addClass("display-menu");
                $('.isHomepage').addClass('sticky');
                $("#nav-mobile-container").addClass("fixed-menu");
                $("#nav-mobile-container").addClass('fade-in-right');
                $(".back-to-top-button").css({
                    'z-index': '99'
                });
                $('html, body').css({
                    'overflow': 'hidden'
                });
                $('.iconToggleContainer').addClass("d-none");
            }, 300);
        } else {
            $("#nav-mobile-container").removeClass("show");
            $('.menu-mobile').removeClass("menu-display");
            $('.isHomepage').removeClass('sticky');
            $("#nav-mobile-container").removeClass("fixed-menu");
            $("#nav-mobile-container").removeClass('fade-in-right');
            $("#menu-nav-mobile").removeClass("display-menu");
            $('#menu-nav-mobile').removeClass('show');
            $('.iconToggleContainer').removeClass("d-none");
            $(".back-to-top-button").css({
                'z-index': '1000'
            });
            $('html, body').css({
                'overflow': 'auto'
            });
        }
    } else {
        $("#nav-mobile-container").removeClass("fixed-menu");
        $("#nav-mobile-container").removeClass('fade-in-right');
        $('#menu-nav-mobile').removeClass('show');
        $("#menu-nav-mobile").removeClass("display-menu");
        $('html, body').css({
            'overflow': 'auto'
        });
    }
});

$(document).on('mouseenter', '.nav-item[data-subcat-loaded=false]', function() {
    // Call Ajax
    $.ajax({
        dataType: 'json',
        type: 'post',
        url: $('#url-subcat').val(),
        data: {
            categoryID: $(this).attr('data-category-id')
        },
        success: function (data) {
            for (var i = 0; i < data.categories.length; i++) {
                var container = $('.text-container-column[data-menu-item=' + data.categories[i].categoryID + ']');

                if (container) {
                    container.append(data.categories[i].renderedHTML);
                }
            }
        },
        error: function () {
        }
    });
    $(this).attr('data-subcat-loaded', true);
})

$('.navbar-nav .nav-link, .navbar-nav .dropdown-link, .dropdown-menu-more .dropdown-link, .navbar-nav .header-bloc-image').click(function() {
    if ($(this).text().trim() !== "ACADEMY") {
        // create a local storage to trigger GTM event if menu is clicked
        localStorage.setItem('menu_click', true);
    } else {
        dataLayer.push({
            'menu': {lv2: '', lv1: $(this).text().trim()},
            'event':'menu_click'         
        });
    }
    
});

$('#navbar-menu-myaccount').on('click mouseleave mouseout', function() {
    $('#myaccount-toggle-menu').unbind( "mouseleave" );
    $("#myaccount-toggle-menu").removeAttr("style");
});

function SortByValue(a, b){
    var aValue = parseInt(a.value);
    var bValue = parseInt(b.value);
    return ((aValue < bValue) ? 1 : ((aValue > bValue) ? -1 : 0));
}

var desktopMenu = $('#sorted-menu-category');
if(desktopMenu.length){
    var itemSelectorDesktop = $('#sorted-menu-category li > .itemIndex');
    var arrayItemsDesktopMenu = [].slice.call(itemSelectorDesktop);
    arrayItemsDesktopMenu.sort(SortByValue);

    var sortedMenuItemsDesktop = [];
    $.each( arrayItemsDesktopMenu, function( index, item ) {
        sortedMenuItemsDesktop.push($(item).parent());
    });
    $('.catalog-item').remove();
    $.each( sortedMenuItemsDesktop, function( index, item ) {
        item.insertAfter('.add-after-item');
    });
}

var mobileMenu = $('#menu-nav-mobile');
if(mobileMenu.length){
    var itemSelector = $('#menu-nav-mobile li > .itemIndex');
    var arrayItemsmobileMenu = [].slice.call(itemSelector);
    arrayItemsmobileMenu.sort(SortByValue);

    var sortedMenuItems = [];
    $.each( arrayItemsmobileMenu, function( index, item ) {
        sortedMenuItems.push($(item).parent());
    });
    $('.catalog-item-mobile').remove();
    $.each( sortedMenuItems, function( index, item ) {
        item.insertAfter('.add-after-item-mobile');
    });
}

$('body').on('click', function (e) {
    if ($(e.target).parents('.dropdown-menu').length === 0 && !$(e.target).parent().hasClass('dropdownPCIS')) {
        $('.dropdown-menu').hide();
        $('.dropdown-menu.active').removeClass('active');
        $('.dropdownPCIS, .dropdownPCIS button').removeClass('active tab-navigation show-menu');
    }
});