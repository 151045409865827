'use strict';

/* eslint-disable no-undef */

var util = require('../components/util');

function waitForElm(selector) {
    return new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver((mutations) => { // eslint-disable-line no-unused-vars
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    });
}

function scrollBtnHandler($btnEl) {
    var buttonBillingCart = $('#js-billing-step-btn');
    var shippinButtonForm = $('#js-shippin-button');
    if ($(window).scrollTop() >= $btnEl.offset().top + $btnEl.outerHeight() - $(window).height()) {
        shippinButtonForm.removeClass('d-hide');
        $('#button-billing-cloned').removeClass('d-hide');
        $('.validate-billing-btn').removeClass('d-hide');
        buttonBillingCart.addClass('d-none');
    } else {
        shippinButtonForm.addClass('d-hide');
        $('#button-billing-cloned').addClass('d-hide');
        $('.validate-billing-btn').addClass('d-hide');
        buttonBillingCart.removeClass('d-none');
    }
}

function splitText(text, textBreak) {
    var first = text.substring(0, textBreak);
    var second = text.substring(textBreak);
    var aux = second.substring(0, second.indexOf(" "));
    var spaceIndex = second.indexOf(" ");
    second = " " + second.substring(spaceIndex + 1);
    first = first.substring(0, textBreak) + aux;
    var bothTextes = [first, second];
    return bothTextes;
}

function detectLastFocusableElement(elemList) {
    for (var i = elemList.length - 1; i >= 0; i--) {
        if (elemList[i].offsetWidth > 0 && elemList[i].offsetHeight > 0)
            return elemList[i];
    }
}

function detectFirstFocusableElement(elemList) {
    var firstEl;
    elemList.forEach(function (item) {
        if (item.offsetWidth > 0 && item.offsetHeight > 0 && !firstEl) {
            firstEl = item;
        }
    })
    return firstEl;
}

function trapFocus(element) {
    var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
    var firstFocusableEl = detectFirstFocusableElement(focusableEls);
    var lastFocusableEl = detectLastFocusableElement(focusableEls);
    var KEYCODE_TAB = 9;

    element.addEventListener('keydown', function (e) {
        var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) /* shift + tab */ {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else /* tab */ {
            focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            firstFocusableEl = detectFirstFocusableElement(focusableEls);
            lastFocusableEl = detectLastFocusableElement(focusableEls);
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
}

var Utilites = {
    useMobileView: $(window).outerWidth() <= 768,
    menuMoreHandler: function () {
        var navbuttonmore = $('.nav-button-more');

        if (navbuttonmore.length < 1) return;

        navbuttonmore.hover(function () {
            $(this).parents('.nav-item').addClass('hide-menu');
        });

        navbuttonmore.on('click', function () {
            var self = $(this);
            var parents = self.parents('.nav-item');
            parents.toggleClass('show-menu');
            $('header').toggleClass('menu-more-show');
            if (!parents.hasClass('show-menu')) {
                parents.find('.dropdown-item').removeClass('showing-sub');
            }
        });
    },
    headerIgraalAdapteHandler: function () {
        var header = $('header');

        if (header.length > 0) {
            var topMenu = header.offset().top + header.outerHeight() - $(document).scrollTop();

            var dropdownMenu = $(".HPrevamp-header #menus-container .navbar-top-categories .nav-item .dropdown-menu");

            if (dropdownMenu.length === 0) {
                dropdownMenu = $('#categories-menu-items.navbar-nav .nav-item .dropdown-menu');
            }

            dropdownMenu.css('top', topMenu + 'px');

            var startTime = new Date().getTime();
            var interval = setInterval(function(){
                if(new Date().getTime() - startTime > 60000){
                    clearInterval(interval);
                    return;
                }
                var header = $('header');
                var topMenu = header.offset().top + header.outerHeight() - $(document).scrollTop();
                dropdownMenu.css('top', topMenu + 'px');
            }, 2000);
        }
    },
    footerAppendCartPageHandler: function () {
        var pageMain = $('.page-main');
        var pageCart = $('.checkout-page[data-action="Cart-Show"]');
        var footer = $('footer');

        if (pageCart.length < 1 ) return;

        pageMain.append(footer);
    },
    showPopinVideoSkeepers: function () {
        var skeepersPopover = $('.skeepers-popover');
        var skeepersSticky = $('.skeepers-sticky');

        if (skeepersPopover.length < 1) return;

        $('#skeepersThumbnail').on('click', function () {
            $('.skeepers-item').find('#player').attr('data-autoplay', "0");

        });

        window.onTeesterReady = (window.onTeesterReady || []);
        window.onTeesterReady.push((SDK) => {
            const player = SDK.init({
                type: 'player',
                args: {
                    key: $('#skeepres-player').attr('data-key'),
                    product: $('#skeepres-player').attr('data-product'),
                    allow_share: "0",
                    allow_rate: "0",
                    allow_ambassador: "0",
                    allow_buy: "0",
                    autoplay : "0",
                    preview: true
                }
            }, document.querySelector('#skeepres-player'));
            player.onPlay(() => {
                player.mute();
            });
        });
        skeepersSticky.find('button.close').on('click', function () {
            var srcAttr = skeepersSticky.find('iframe').attr('src');
            skeepersSticky.addClass('d-none');
            skeepersSticky.find('iframe').attr('src', srcAttr);
            skeepersSticky.find('#player').attr('data-autoplay', "0");
        });
        skeepersSticky.find('.play').on('click', function (e) {
            e.preventDefault();
            $('#skeepersPopover').find('#player').attr('data-autoplay', "0");
            skeepersSticky.addClass('hide');
        });

        if (Utilites.useMobileView) {
            $('#skeepersPopoverMobile').on('click', function (e) {
                e.preventDefault();
                window.onTeesterReady = (window.onTeesterReady || []);
                window.onTeesterReady.push((SDK) => {
                    const player = SDK.init({
                        type: 'player',
                        args: {
                            key: $('#skeepres-player').attr('data-key'),
                            product: $('#skeepres-player').attr('data-product'),
                            allow_share: "0",
                            allow_rate: "0",
                            allow_ambassador: "0",
                            allow_buy: "0",
                            autoplay : "0",
                            preview: true
                        }
                    }, document.querySelector('#skeepres-player'));
                    player.onPlay(() => {
                        player.mute();
                    });
                });
                $('#skeepersPopover').find('#player').attr('data-autoplay', "0");
            });

            if ($('.newpdp-carousel-container').length) {
                var topNewpdpCarousel = $('.newpdp-carousel-container').offset().top;

                skeepersSticky.css({
                    'top' : topNewpdpCarousel + 'px'
                });
            }
        }
    },
    stopPlayingVideoSkeepers: function () {
        var skeepersPopover = $('#skeepersPopover');

        if (skeepersPopover.length < 1) return;

        $(document).on('click', '#skeepersPopover button.close', function (e) {
            e.preventDefault();
            var srcAttr = skeepersPopover.find('iframe').attr('src');
            skeepersPopover.find('iframe').attr('src', srcAttr);
            skeepersPopover.find('#player').attr('data-autoplay', "0");
            skeepersPopover.removeClass('is-open');
            $('.skeepers-sticky').removeClass('hide');
        })
    },
    unwantedScrollKeyboard: function () {

        $("body").bind('touchmove', function (e) {
            e.preventDefault()
        });
    },
    checkInputHandler: function () {
        var shippingAddressTwo = $('#shippingAddressTwo');
        var billingAddressTwo = $('#billingAddressTwo');

        if (shippingAddressTwo.length < 1 || billingAddressTwo.length < 1) return;

        if (shippingAddressTwo.val() !== "") {
            shippingAddressTwo.addClass('is-valid');
        }

        if (billingAddressTwo.val() !== "") {
            billingAddressTwo.addClass('is-valid');
        }
    },
    triggerFocusPopin: function () {
        var optinShadeFinder = $('#optin-vto-shadefiner');
        var lastFocus;

        if (optinShadeFinder.length < 1) return;

        optinShadeFinder.on('shown.bs.modal', function () {
            lastFocus = $(':focus');
            $(this).find('button.close').focus();
            trapFocus($('#optin-vto-shadefiner')[0]);
        });
        optinShadeFinder.on('hidden.bs.modal', function () {
            if (lastFocus) lastFocus.focus();
        });
    },
    headerCountrySelectorPop: function () {
        var $countrySelectorpop = $('#countrySelectorpop');
        var topBannerAnnimation = $("#menus-container .top-animation-banner");
        var topBannerHeight = topBannerAnnimation.height();
        var headerHeight = $('.menu-bottom-container').height();

        if ($countrySelectorpop.length < 1) return;

        if (Utilites.useMobileView) {
            if (topBannerAnnimation.length) {
                $countrySelectorpop.find('.modal__overlay').css('top', headerHeight + topBannerHeight + 'px');
            } else {
                $countrySelectorpop.find('.modal__overlay').css('top', headerHeight + 'px');
            }
        }
    },
    alertOutOfStockHandler: function () {
        var topBannerAnnimation = $("#menus-container .top-animation-banner");
        var topBannerHeight = topBannerAnnimation.height();
        var headerHeight = $('.menu-bottom-container').height();
        var alertoutofstock = $('#js-alert-mobile');

        if (alertoutofstock.length < 1) return;

        if (Utilites.useMobileView) {
            alertoutofstock.find('#js-alert-form-email').addClass('d-none');
            alertoutofstock.find('.noAvailableAlertMobile').removeClass('hidden');
            alertoutofstock.find('.noAvailableAlertDesktop').addClass('hidden');
            alertoutofstock.find('.checkbox-alertoutofstock').addClass('d-none');

            if (topBannerAnnimation.length) {
                alertoutofstock.parents('.alert-product-form-nosale').css('top', headerHeight + topBannerHeight + 'px');
            } else {
                alertoutofstock.parents('.alert-product-form-nosale').css('top', headerHeight + 'px');
            }

            alertoutofstock.on('click.alertOutOfStockHandler', function () {
                var self = $(this);

                self.find('#js-alert-form-email').removeClass('d-none');
                self.find('.checkbox-alertoutofstock').removeClass('d-none');
            })
        }
    },
    inputEmailOutOfStockHandler: function () {
        var inputEmailOutOfStock = $(".alert-product-form-nosale .alertProductEmail");
        var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

        if (inputEmailOutOfStock.length < 1) return;

        $('.create-alert-wrapper').find('.create-alert-submit-nosale').attr('disabled', true);
        $(".alertoutofstock-no-opt .alertProductEmail").on("keyup change", function () {
            $('.alertoutofstock-no-opt .invalid-feedback').hide();

            if (filter.test($(this).val())) {
                $('.alertoutofstock-no-opt .invalid-feedback').removeClass('d-block').addClass('d-none');
                $('.create-alert-wrapper').find('.create-alert-submit-nosale').attr('disabled', false);
            } else if ($(this).val().trim() === '') {
                $('.alertoutofstock-no-opt .invalid-feedback').addClass('d-block').removeClass('hidden d-none');
            } else {
                $('.alertoutofstock-no-opt .invalid-feedback').addClass('d-block').removeClass('hidden d-none');
                $('.create-alert-wrapper').find('.create-alert-submit-nosale').attr('disabled', true);
            }
        });


    },
    checkboxInputOOSHandler: function () {
        var checkboxAlertoutofstock = $('.checkbox-alertoutofstock');
        var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

        if (checkboxAlertoutofstock.length < 1) return;

        $('.create-alert-wrapper').find('.create-alert-submit-nosale').attr('disabled', true);

        $(".alertoutofstock-opt .alertProductEmail").on("keyup change", function () {
            $('.alertoutofstock-opt .invalid-feedback').hide();

            if (filter.test($(this).val())) {
                $('.alertoutofstock-opt .invalid-feedback').removeClass('d-block').addClass('d-none');
                if (checkboxAlertoutofstock.find('#add-alertoutofstock').is(":checked")) {
                    $('.create-alert-wrapper').find('.create-alert-submit-nosale').attr('disabled', false);
                }
            } else if ($(this).val().trim() === '') {
                $('.alertoutofstock-opt .invalid-feedback').addClass('d-block').removeClass('hidden d-none');
            } else {
                $('.alertoutofstock-opt .invalid-feedback').addClass('d-block').removeClass('hidden d-none');
                $('.create-alert-wrapper').find('.create-alert-submit-nosale').attr('disabled', true);
            }
        });
        checkboxAlertoutofstock.find('#add-alertoutofstock').change(function () {
            if ($(this).is(":checked") && filter.test($(".alertoutofstock-opt .alertProductEmail").val())) {
                $('.create-alert-wrapper').find('.create-alert-submit-nosale').attr('disabled', false);
            } else {
                $('.create-alert-wrapper').find('.create-alert-submit-nosale').attr('disabled', true);
            }
        });
    },
    pdpStickyWrapperHandler: function () {
        var pdpStickyWrapper = $('#pdp-sticky-wrapper');
        var headerHeight = $('header.HPrevamp-header').height();

        if (pdpStickyWrapper.length < 1) return;

        if (!Utilites.useMobileView) {
            pdpStickyWrapper.css({
                'top': headerHeight + 'px',
                'height': 'auto'
            });
        }
    },
    checkoutOrderSummaryToggleHandler: function () {
        var cardOrderSummary = $('#card-order-summary');
        var buttonToggleOrder = cardOrderSummary.find('.review-summary');

        if (cardOrderSummary.length < 1) return;

        if (Utilites.useMobileView) {
            cardOrderSummary.addClass('hide-order');
        }
        buttonToggleOrder.on('click.orderSummaryHandler', function () {
            var self = $(this);
            self.parents('.card-order-summary').toggleClass('hide-order');

            if (Utilites.useMobileView) {
                $("html, body").animate({ scrollTop: 0 }, "slow");
            }
        });
    },
    checkoutMaxSamplesToggleHandler: function () {
        var maxSamplesSection = $('#samples-list-container');
        var buttonToggleSamples = $('.cart-samples-content').find('.cart-samples-content-title');

        if (maxSamplesSection.length < 1) return;

        buttonToggleSamples.on('click', function () {
            var self = $(this);
            self.parents('.cart-samples-continer').find('#samples-list-container').toggleClass('hide-samples');
            $('.cart-samples-content, .max-samples').toggleClass('hidden-samples');
        });
    },
    checkoutWrappingToggleHandler: function () {
        var WrappingSection = $('#wrapping-container');
        var buttonToggleWrapping = $('#wrapping-container').find('.js--toggler-title-jp');

        if (WrappingSection.length < 1) return;

        buttonToggleWrapping.on('click', function () {
            var self = $(this);
            self.parents('#wrapping-container').toggleClass('hide-wrapping');
            $('.js--toggler-title-jp').toggleClass('hidden-wrapping');
        });
    },
    carShowStickyPriceHandler: function () {
        var cartShow = $('.page').attr('data-action');

        if (Utilites.useMobileView) {
            if (cartShow === 'Cart-Show') {
                $('.page').parents('body').addClass('cart-page');
            } else {
                return;
            }
        }
    },
    stickyPricePoductHandler: function () {
        var cartSummaryFooter = $('.cart-summary-footer');
        var pageCartContainer = $('#cart-page-container');
        var buttonCheckoutCart = $('#cart-checkout-continue-button');

        if (pageCartContainer.length < 1) return;

        if (Utilites.useMobileView) {
            cartSummaryFooter.append('<div id="button-checkout-cloned"></div>');
            $('#button-checkout-cloned').append(buttonCheckoutCart.html());
            buttonCheckoutCart.addClass('d-hide');

            $(window).scroll(function () {
                if ($(window).scrollTop() >= pageCartContainer.offset().top + pageCartContainer.outerHeight() - $(window).height()) {
                    buttonCheckoutCart.removeClass('d-hide');
                    $('#button-checkout-cloned').addClass('d-none');
                } else {
                    buttonCheckoutCart.addClass('d-hide');
                    $('#button-checkout-cloned').removeClass('d-none');
                }
            });
        }
    },
    stickyBtnPoductHandler: function () {
        var shippingPageDisplay = $('.shipping-page-display');
        var recapBillingsection = $('.section-billing-recap');
        var buttonBillingCart = $('#js-billing-step-btn');
        var shippinButtonForm = $('#js-shippin-button');

        if (shippingPageDisplay.length < 1) return;

        if (Utilites.useMobileView) {
            recapBillingsection.append('<div class="next-step-button" id="button-billing-cloned"></div>');
            $('#button-billing-cloned').append(buttonBillingCart.html());
            buttonBillingCart.append($('.validate-billing-btn').html());
            buttonBillingCart.find('.submit-billing-address').addClass('d-none');
            shippinButtonForm.addClass('d-hide');
            $('#button-billing-cloned').addClass('d-hide');

            $(window).scroll(function () {
                if ($('.form-billing').length > 0) {
                    scrollBtnHandler($('.billing-address'));
                } else {
                    scrollBtnHandler(shippingPageDisplay);
                }
            });
        }
    },
    stickyBtnPaymentHandler: function () {
        var shippingPageDisplay = $('.shipping-page-display');
        var buttonPaymentCart = $('#js-payment-step-btn');

        if (shippingPageDisplay.length < 1) return;

        var cloned_btn = "";
        var data_locale = $('#widget-locale').data('locale');
        if (data_locale == "en_US") {
            cloned_btn = '<div class="col-12 next-step-button payment-btn-US" id="button-payment-cloned"></div>'
        } else {
            cloned_btn = '<div class="col-12 next-step-button" id="button-payment-cloned"></div>';
        }

        if (Utilites.useMobileView) {
            shippingPageDisplay.after(cloned_btn);
            $('#button-payment-cloned').append(buttonPaymentCart.html());

            $(window).scroll(function () {
                if ($(window).scrollTop() >= shippingPageDisplay.offset().top + shippingPageDisplay.outerHeight() - $(window).height()) {
                    buttonPaymentCart.addClass('d-none');
                } else {
                    if ($('input:checked[name="payment-option"]').val() === 'card' || $('input:checked[name="payment-option"]').val() === 'bml') {
                        buttonPaymentCart.removeClass('d-none');
                    } else {
                        buttonPaymentCart.addClass('d-none');
                    }
                }
            });
        }
    },
    closeVideoPopoverPopin: function () {

        $(document).on('click touchstart tap', '.experience-popover-video button.close', function () {
            var popovervideoWrap1 = $('.experience-popover-video #vimeoWrap1 iframe')[0];
            var popovervideoWrap2 = $('.experience-popover-video #vimeoWrap2 iframe')[0];

            var player1 = $f(popovervideoWrap1); // eslint-disable-line no-undef
            var player2 = $f(popovervideoWrap2); // eslint-disable-line no-undef

            player1.api('pause');
            player2.api('pause');

        });
    },

    resizeMethodeHandler: function () {
        $(window).resize(function () {
            Utilites.headerCountrySelectorPop();
            Utilites.heightImageBackgroundHandler();
            Utilites.adapteHeightHomeProductFinder();
            Utilites.headerIgraalAdapteHandler();
        });
    },

    setModalIframeTabindex: function () {
        waitForElm('#YMK-module-iframe').then((elm) => {
            elm.setAttribute('tabindex', '-1');
        });
    },
    triggerCheckedImage: function () {
        var jsAnswerImg = $('.js-core-answer-img');
        var btnValidate = $('.answer-plain .form-send');

        if (jsAnswerImg.length < 1) return;

        $('.js-core-current-answer').on('change', function () {
            if ($(this).is(":checked")) {
                $(this).parents('.js-core-answer-img').addClass('is-checked').siblings(".js-core-answer-img").removeClass('is-checked');
                btnValidate.find('.btn-send').prop("disabled", false);
                btnValidate.removeClass('hide');
            }
        })
    },
    heightImageBackgroundHandler: function () {
        var imageBkgrd = $('.image-bkgrd');
        var answerPlain = $('.answer-plain');
        var recommendationsPlain = $('.product-finder-questions-plain');
        var header = $('header');
        var outerHeightWindow = $(window).height();
        var outerHeightHeader = header.height();

        if (imageBkgrd.length) {
            imageBkgrd.find('img').css({
                'height': outerHeightWindow - outerHeightHeader + 'px'
            });
        }

        if (answerPlain.length) {
            answerPlain.css({
                'height': outerHeightWindow - outerHeightHeader + 'px'
            });
        }

        if (recommendationsPlain.length) {
            recommendationsPlain.css({
                'height': outerHeightWindow - outerHeightHeader + 'px'
            });
        }

        $(window).on("resize", function () {
            $("#log").append("<div>Handler for `resize` called.</div>");
        });

    },
    appendVideoProductFinderHandler: function () {
        var jsProductCarousel = $('.js-productCarousel');
        var useMobileView = $(window).outerWidth() <= 768;

        if (jsProductCarousel.length < 1) return;
        if (useMobileView) {
            jsProductCarousel.find('.product-capacity').after('<div class="video-wrapper"></div>');
            jsProductCarousel.find('.video-wrapper').append($('.slide-wrapper').html());
        }
    },
    adapteHeightHomeProductFinder: function () {
        var imageBkgrd = $('.image-bkgrd');
        var srcImageBkgrd = imageBkgrd.find('img').attr('src');
        var heightImageBkgrd = imageBkgrd.find('img').attr('style');
        var useMobileView = $(window).outerWidth() <= 768;

        if (imageBkgrd.length < 1 || srcImageBkgrd.length === 0) return;

        if (!useMobileView) {
            imageBkgrd.attr({
                'style': 'background-image:url(' + srcImageBkgrd + ');' + heightImageBkgrd + ''
            });
        }

    },
    showMoreShowLessHandler: function () {
        var data_locale = $('#widget-locale').data('locale');
        if (data_locale !== 'en_US') {
            $('.modal-scroll-class').css('max-height', '500px');
        }

        if ($('.rest-of-description').length) return;

        if ($(".legal-text").length > 0 && data_locale === 'en_US') {
            var textLimit = 600;
            var text = $(".legal-text").html();

            $(".legal-text").parents('.modal-scroll-class').addClass('modal-sf-us');

            if (text.length > textLimit) {
                var textArray = splitText(text, textLimit);
                $(".legal-text").html(textArray[0]);
                $(".legal-text p:last-of-type").append("<span class='vto-sf-show-more'>&nbsp;<span class=\"red\">View more</span></span>");
                $(".legal-text p:last-of-type").append("<span style=\"display: none\" class='rest-of-description'>" + textArray[1] + "</span>");
                $(".rest-of-description p:last-of-type").append("<span style=\"display: none\" class='vto-sf-show-less'>&nbsp; View less </span>");
            } else {
                $(".legal-text").html(text);
            }

            $('.vto-sf-show-more').on('click', function () {
                $(".rest-of-description").show();
                $(".vto-sf-show-less").show();
                $(".vto-sf-show-more").hide();

                if ($('.vto-sf-show-less').length > 1) {
                    $('.vto-sf-show-less').last().hide();
                }
            });

            $('.vto-sf-show-less').on('click', function () {
                $(".rest-of-description").hide();
                $(".vto-sf-show-less").hide();
                $(".vto-sf-show-more").show();
            });
        }
    },
    VTOcheckboxHandler : function () {
        if ( $('.sf-vto-optin-checkbox').length === 0) {
            $('.sf-vto-for-default .modal-body').addClass('sf-vto-checkbox-removed');
        } else {
            $('.sf-vto-for-default .modal-body').removeClass('sf-vto-checkbox-removed');
        }
    },
    PageDesignerMenuClickHandler : function () {
        $(document).on('click', '.page-designer-item', function () {
            if ($('.dropdown-menu').hasClass('show active')) {
                $('main').addClass('new-menu-expanded');
            } else {
                $('main').removeClass('new-menu-expanded');
            }
        });

        $(document).on('click', '.icon-return-back.new-menu-icon', function () {
            setTimeout(() => {
                $('main').addClass('new-menu-expanded');
            }, 0)
        });

        if (!util.isMobile() || $(window).width() > 992) {
            $(document).on('scroll', function () {
                $('main').removeClass('new-menu-expanded');
            });

            $(document).on('click', function (e) {
                if ($(e.target).closest(".dropdown-menu, #categories-menu-items").length === 0) {
                    $('main').removeClass('new-menu-expanded');
                }
            });
        }

        $('.icon-big-link, .big-link').each(function() {
            $( this ).on('click', function () {
                $(this).closest('.MENU-component-img-and-links').find('.sub-links-container').toggleClass('d-none');
                $(this).toggleClass('rotate-arrow-icon');
            })
        });

        $(document).on('click', '.icon-country-selector', function () {
            $('#btn-country-selector').trigger('click');
        });
    },
    shadeFinderSuggestionsHandler: function () {
        $(document).on('click', '#skintone-detail', function (e) {

            e.preventDefault();

            $('#YMK-wrapper').find('.suggestions-shade-details').addClass('show');
            $('#YMK-wrapper').find('.YMK-centent').addClass('hide');

            if ($('.skintone-action-revamp').length) {
                var sfvisualsVTO = $('.product-view-suggestion .sfvisualsVTO');
                
                $('.YMK-wrapper2').find('.suggestions-shade-details').html(sfvisualsVTO.html());
            }
        });

        $(document).on('click', '#back-shade', function (e) {
            var self = $(this);

            e.preventDefault();
            self.parents('#YMK-wrapper').find('.suggestions-shade-details').removeClass('show');
            self.parents('#YMK-wrapper').find('.YMK-centent').removeClass('hide');
        });

        $(document).on('click' , '.shade-nav-list li', function () {
            var self = $(this);

            $('.shade-wrapper-head').each(function () {
                var $this = $(this);
                if (self.attr('id') === $this.data('shade')) {
                    self.addClass('d-none').siblings('.d-none').removeClass('d-none');
                    $this.addClass('active').siblings('.active').removeClass('active');
                }
            });
        });
    },
    menuFixedEMPHandler: function () {
        if ($('.EMP-banner-component').length) {
            $('.HPrevamp-header').addClass('isEMPPage');
        }
    },

    vimeoEMPvideoHandler: function () {
        var EMPItem = $('.EMP-video-popover');

        if (EMPItem.length) {
            $(document).on('click', '.js-open-modal-video', function () {
                var self = $(this);

                self.parents('.experience-component').find('.EMP-video-popover iframe').attr('data-src', self.attr('data-vimeo'));
                self.parents('.experience-component').find('.EMP-video-popover iframe').attr('src', self.attr('data-vimeo'));
                self.parents('.experience-component').find('.EMP-video-popover').addClass('is-open');
            });

            EMPItem.find('.close').on('click', function () {
                var self = $(this);
                var vimeoWrap = self.parents('.EMP-video-popover').find('.popover-video');

                self.parents('.EMP-video-popover').removeClass('is-open');
                vimeoWrap.html(vimeoWrap.html());
            });
        }
    },

    megaMenuCategorieLinkHandler: function () {
        var pageDesignerItem = $('.page-designer-item');

        if (pageDesignerItem.length) {

            pageDesignerItem.each(function () {
                var self = $(this);

                if (self.find('.dropdown-menu').is(':empty')) {
                    var linkDekstop = self.find('button').data('href');
                    var linkMobile = self.find('button').data('url');
                    var linkVal = self.find('button').text();
                    var mobileColorCSS = self.find('button').data('mobilecolor') ? self.find('button').data('mobilecolor') : '';

                    if (Utilites.useMobileView) {
                        self.append('<a href="'+ linkMobile +'" class="nav-item" '+ mobileColorCSS +'>'+ linkVal +'</a>');
                        self.find('.toggler-icon').remove();
                    } else {
                        self.append('<a href="'+ linkDekstop +'" class="nav-subitem nav-link dropdown-toggle">'+ linkVal +'</a>');
                    }
                    self.find('button').remove();
                }
            });
        }
    },

    limitMaxlenghtTextHandler: function () {
        var EMPClaimSlider = $('.EMP-claim-slider');

        if (EMPClaimSlider.length) {
            var EMPClaimItem = EMPClaimSlider.find('.EMP-claim-item');
            var limitTextLength = 267;

            if (!Utilites.useMobileView) {
                EMPClaimItem.each(function () {
                    var self = $(this);
                    var text = self.find('.EMP-claim-text').text();
                    var len = text.length;

                    if (len > limitTextLength) {
                        self.find('.EMP-claim-text').text(self.find('.EMP-claim-text').text().substr(0, limitTextLength) + '...');
                    }
                });
            }
        }
    },

    birthdayTypeHandler: function () {
        var birthday = $('#registration-form-birthday-type-revamp');
        var dataLocale = birthday.data('locale');
        var date_birthday = birthday.data('birthday');

        if (birthday.length) {
            var pdate = date_birthday.split('-');
            var yy = parseInt(pdate[0]);
            var mm = parseInt(pdate[1]);
            var dd = parseInt(pdate[2]);

            if ((dd.toString() !== 'NaN') && (mm.toString() !== 'NaN') && (yy.toString() !== 'NaN')) {
                var typeDate = yy + '/' + mm + '/' + dd;

                if (['fr_FR', 'en_IM'].includes(dataLocale)) {
                    typeDate = dd + '/' + mm + '/' + yy;
                } else if (dataLocale === 'en_US') {
                    typeDate = mm + '/' + dd + '/' + yy;
                }

                $('#registration-form-birthday-type-revamp').val(typeDate);
            }
        }
    },

    activeMenuAccountHandler: function () {
        if ($('.account-sidebar').length) {
            var currentAction = $('.page').data('action');
            if (currentAction === 'Account-Show') {
                $('.account-sidebar').find('.item.my-account, .nav-account-item.my-account').addClass('active');
                $('.account-sidebar').find('.item, .nav-account-item').not('.my-account').removeClass('active');
            } else if (currentAction === 'Account-EditProfile') {
                $('.account-sidebar').find('.item.my-infos, .nav-account-item.my-infos').addClass('active');
                $('.account-sidebar').find('.item, .nav-account-item').not('.my-infos').removeClass('active');
            } else if (currentAction === 'Order-History' || currentAction === 'Order-Details') {
                $('.account-sidebar').find('.item.my-order-history, .nav-account-item.my-order-history').addClass('active');
                $('.account-sidebar').find('.item, .nav-account-item').not('.my-order-history').removeClass('active');
            } else if (currentAction === 'Address-AddAddress' || currentAction === 'Address-EditAddress' || currentAction === 'Address-List') {
                $('.account-sidebar').find('.item.add-address, .nav-account-item.add-address').addClass('active');
                $('.account-sidebar').find('.item, .nav-account-item').not('.add-address').removeClass('active');
            } else if (currentAction === 'Account-ProSpace') {
                $('.account-sidebar').find('.item.add-address, .nav-account-item.pro-program').addClass('active');
                $('.account-sidebar').find('.item, .nav-account-item').not('.pro-program').removeClass('active');
            } else {
                $('.account-sidebar').find('.item.payment-methods, .nav-account-item.payment-methods').addClass('active');
                $('.account-sidebar').find('.item, .nav-account-item').not('.payment-methods').removeClass('active');
            }

            $('.account-navbar').animate({scrollLeft: $('.item.active').position().left}, 500);
        }
    },

    removeAriaHiddenMenuHandler: function () {
        var pageDesignerItem = $('.page-designer-item');

        if (pageDesignerItem.length) {
            pageDesignerItem.each(function () {
                var self = $(this);

                self.find('.dropdown-menu').removeAttr('aria-hidden');
            });
        }
    },

    dropdownOrderSortHandler: function () {
        var selectSortOrder = $('.select-sort-order');

        if (selectSortOrder.length) {
            selectSortOrder.find('.selected-sort-option').on('click', function () {
                var self = $(this);

                self.parents('.sort-all-orders').addClass('active');
            });

            selectSortOrder.parents('.sort-all-orders').find('.dropdown-order-list .sortBy-button').each(function () {
                var self = $(this);
                var dataUrl = self.attr('data-url');

                self.on('click', function () {
                    var data = {
                        oldest: false,
                        isAjax: true
                    };

                    if ($(this).attr('id') === 'oldestOrder') {
                        data.oldest = true;
                    }

                    $.ajax({
                        url: dataUrl,
                        method: 'GET',
                        data: data,
                        dataType: 'html',
                        success: function (html) {
                            $('.myAccount_revamp .order_body').html($($.parseHTML(html)));
                        }
                    });

                    self.parents('.sort-all-orders').find('.selected-sort-option').html(self.html());
                    self.parents('.sort-all-orders').removeClass('active');
                });
            });

            $(document).on('click', function (e) {
                if (!$(e.target).closest('.sort-all-orders').length) {
                    $('.sort-all-orders').removeClass('active');
                }
            });
        }
    },

    displayPageSectionAccountHandler: function () {
        var btnRegister = $('#btn-register');
        var btnTrackOrder = $('#btn-trackOrder');
        var btnBackLoginPage = $('.return-login-redirect');
        var formTrackOrder = $('#form-track-order');

        if (btnRegister.length) {

            btnRegister.on('click', function () {
                var loginContent = $(this).parents('.login-revamp-container').find('#login-form');
                var registerContent = $(this).parents('.login-revamp-container').find('#register-form');

                loginContent.addClass('d-none');
                registerContent.removeClass('d-none');

                dataLayer.push({
                    'event':'signUp'
                });
            });
        }

        if (btnBackLoginPage.length) {

            btnBackLoginPage.on('click', function () {
                var loginContent = $(this).parents('.login-revamp-container').find('#login-form');
                var registerContent = $(this).parents('.login-revamp-container').find('#register-form');
                var trackOrderContent = $(this).parents('.login-revamp-container').find('#form-track-order');

                loginContent.removeClass('d-none');
                registerContent.addClass('d-none');
                trackOrderContent.addClass('d-none');
            });
        }

        if (btnTrackOrder.length) {

            btnTrackOrder.on('click', function () {
                var loginContent = $(this).parents('.login-revamp-container').find('#login-form');
                var trackOrderContent = $(this).parents('.login-revamp-container').find('#form-track-order');

                loginContent.addClass('d-none');
                trackOrderContent.removeClass('d-none');
            });
        }

        if (formTrackOrder.length) {


            formTrackOrder.find('.button-order').attr('disabled', 'disabled');

            formTrackOrder.find('.form-group input').keyup(function() {
                $('.form-group input').each(function() {
                    if ($(this).val() !== '') {
                        formTrackOrder.find('.button-order').attr('disabled', false);
                    } else {
                        formTrackOrder.find('.button-order').attr('disabled', true);
                    }
                });
            });
        }
    },

    visitedProductsSliderHandler : function () {
        window.addEventListener('einsteinCarouselLoaded', () => {
            $('#visitedProductsSection .account-product-slider').owlCarousel({
                items: 4,
                center: false,
                nav: true,
                dots: true,
                dotsEach: 1,
                touchDrag: true,
                loop: false,
                startPosition: 0,
                rewind: false,
                itemElement: 'li',
                stageElement: 'ul',
                navText: [
                    '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                    '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
                ],
                responsive: {
                    0: {
                        items: 2.1,
                        loop: false,
                        center: true,
                        touchDrag: true,
                        margin: 10,
                        nav: true,
                        dots: false,
                        dotsEach: 3,
                    },
                    600: {
                        items: 3,
                        loop: false,
                        center: false,
                        touchDrag: true,
                        margin: 20,
                        nav: true,
                        dots: false,
                        dotsEach: 3,
                    },
                    960: {
                        items: 4,
                        loop: false,
                        center: false,
                        margin: 20,
                        nav: true,
                        dots: false,
                        dotsEach: 2,
                    },
                    1400: {
                        items: 4,
                        loop: false,
                        center: false,
                        margin: 20,
                        nav: true,
                        dots: false,
                        dotsEach: 1,
                    }
                }
            })},
        false,
        );
    },

    deleteAddressRevampHandler: function () {
        if ($('.address-book-revamp').length) {
            $(document).on('click', '.remove-address-revamp', function () {
                $('.address-modal-revamp').addClass('delete-address-modal');
            });
        }
    },

    slideMyAccountReassurance: function () {
        var reassuranceGrid = $('.reassurance-grid');
        var useMobile = $(window).outerWidth() <= 768;

        if (reassuranceGrid.length) {
            if (useMobile) {
                reassuranceGrid.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                    swipe: true,
                    infinite: true,
                    initialSlide: 0,
                    swipeToSlide: true,
                    dots: true,
                    autoplay: false,
                });
            }
        }
    },

    manageAddressNameDisplay: function () {
        if ($('#save-shipping-address').length) {
            $(document).on('click', '#save-shipping-address', function () {
                if ($(this).is(':checked')) {
                    $('.shipping-address-id').removeClass('d-none');
                    $('input[name$=_addressId').prop('required', true).focus();
                } else {
                    $('.shipping-address-id').addClass('d-none');
                    $('input[name$=_addressId').prop('required', false).removeClass('is-invalid').removeAttr('aria-invalid');
                }
            });
        }
    },

    PLPRevampImageHandler: function () {
        if (!Utilites.useMobileView) {
            $('.plp-revamp-section .product-tile .btn-buy-product').hide();

            $(document).on('mouseenter', '.plp-revamp-section .product-tile .activate-switch-on-hover', function () {
                if ($(this).find('.tile-image').length) {
                    $(this).find('.tile-image.first-image').addClass('d-none');
                    $(this).find('.tile-image.second-image').removeClass('d-none');  
                }
            });

            $(document).on('mouseleave', '.plp-revamp-section .product-tile .activate-switch-on-hover', function () {
                if ($(this).find('.tile-image').length) {
                    $(this).find('.tile-image.second-image').addClass('d-none');
                    $(this).find('.tile-image.first-image').removeClass('d-none'); 
                }
            });

            $(document).on('mouseenter', '.plp-revamp-section .product-tile', function () {
                $(this).find('.btn-buy-product').show().removeClass('d-none');
                $(this).find('.footer-tile-plp .ratings').hide();
            });
    
            $(document).on('mouseleave', '.plp-revamp-section .product-tile', function () {
                $(this).find('.btn-buy-product').hide().addClass('d-none');
                $(this).find('.footer-tile-plp .ratings').show();
            });
        }
    },

    trapFocusIsShowingNewsletter: function () {
        var newsletterContainer = $('.newsletter-container');

        if (newsletterContainer.length) {
            if (localStorage.getItem("optinpopin") !== 'cookieNewsletter') {
                newsletterContainer.find('.new-email-input').focus();
                trapFocus($('.newsletter-container')[0]);

                $(window).on('load', function() {
                    $(document).on('click', '#onetrust-accept-btn-handler, .onetrust-close-btn-handler, #accept-recommended-btn-handler, .save-preference-btn-handler', function () {
                        newsletterContainer.find('.new-email-input').focus();
                        trapFocus($('.newsletter-container')[0]);
                    })
                });
            }
        }
    },

    filterpopupRevampHandler: function () {
        var filterpopupRevamp = $('.filterpopupRevamp');
        var HPrevampHeader = $('.HPrevamp-header');

        if (filterpopupRevamp.length) {
            filterpopupRevamp.find('.modal-dialog').css({
                'top': HPrevampHeader.height() + 'px'
            });

            $(document).on('click', '#filter-modal-plp', function () {
                $('body').addClass('lock-body overlay-popin');
                $('.filterpopupRevamp').find('.sorting-content').append($('.filterpopupRevamp .filter-body'));
                trapFocus($('.filterpopupRevamp')[0]);
            });

            $(document).on('click', '.filterpopup button.closemodal', function () {
                $('body').removeClass('lock-body overlay-popin');
            });
        }   

    },

    adapteDescriptionFooterHandler: function () {
        var descriptionFooter = $('.descriptionFooter');
        
        if (descriptionFooter.length) {
            var textLimit = PropertiesPreferences.DESCRIPTIONFOOTERPLP_TEXT_LIMIT;
            var viewMoreTxt = Resources.BUTTON_VIEW_MORE;
            var viewLessTxt = Resources.BUTTON_VIEW_LESS;
            var textDescrFooter = descriptionFooter.html();

            if (textDescrFooter.length > textLimit) {
                var textArray = splitText(textDescrFooter, textLimit);
                $('.descriptionFooter').html(textArray[0]);
                $('.descriptionFooter').append("<span class='descriptionFooter-show-more'>&nbsp;<a href='#'>" + viewMoreTxt + "</a></span>");
                $('.descriptionFooter').append("<span style=\"display: none\" class='rest-of-description'>" + textArray[1] + "</span>");
                $('.rest-of-description').append("<span style=\"display: none\" class='descriptionFooter-show-less'>&nbsp;<a href='#'>" + viewLessTxt + "</a></span>");
            } else {
                $('.descriptionFooter').html(textDescrFooter);
            }

            $('.descriptionFooter-show-more').on('click', function (e) {
                e.preventDefault();

                $('.rest-of-description').show();
                $('.descriptionFooter-show-less').show();
                $('.descriptionFooter-show-more').hide();

                if ($('.descriptionFooter-show-less').length > 1) {
                    $('.descriptionFooter-show-less').last().hide();
                }
            });

            $('.descriptionFooter-show-less').on('click', function (e) {
                e.preventDefault();

                $('.rest-of-description').hide();
                $('.descriptionFooter-show-less').hide();
                $('.descriptionFooter-show-more').show();
            });

        }
    },

    printGuestInvoiceHandler : function () {
        //for Print only
        if ($('#printThis').length && $('.guest-invoice').length) {
            $(document).on('click', '#btnPrint', function () {
                window.print();
            });
        }
    }, 

    focusRadioButtonHandler: function () {
        var focusedRadio = $('input[name=sort-order]');

        if (focusedRadio.length) {
            $(document).on('keydown', '.sorting-plp-option', function (e) {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    $(this).find('input[name=sort-order]').prop('checked', true);
                }
            });
        }
    }, 

    shadeSvgcontainerRevampHandler: function () {
        var shadeSvgcontainerRevamp = $('.shade-svgcontainer-revamp');
        var numberOfElementsToShow = 25;

        if (shadeSvgcontainerRevamp.length) {

            shadeSvgcontainerRevamp.each(function (index, elt) {
                var $this = $(elt);
                var numberOfElements = parseInt($this.find('.shade-spiral-pd').length);
    
                if (numberOfElements > numberOfElementsToShow) {
                    $this.find('.loadMore').show();
                    $this.find('.shade-spiral-pd:gt(' + (numberOfElementsToShow - 1) + ')').hide();
                    $this.find('.loadMore').children('.loadmoreText').empty().html('+' + (numberOfElements - numberOfElementsToShow + 1));
                }
            });

            $('.loadMore').on('click', function () {
                var paletteContainer = $(this).parents('.shade-svgcontainer-revamp');
                var numberOfElements = paletteContainer.find('.shade-spiral-pd').length;
                $(this).attr('aria-pressed', false).hide();
                paletteContainer.find('.showLess').show();
    
                if (numberOfElements > numberOfElementsToShow) {
                    paletteContainer.find('.shade-spiral-pd').show();
                }
            });
    
            $('.showLess').on('click',function () {
                var paletteContainer = $(this).parents('.shade-svgcontainer-revamp');
                var numberOfElements = paletteContainer.find('.shade-spiral-pd').length;
                $(this).attr('aria-pressed', false).hide();
                paletteContainer.find('.loadMore').attr('aria-pressed', true).show();
    
                if (numberOfElements > numberOfElementsToShow) {
                    paletteContainer.find('.shade-spiral-pd:gt(' + (numberOfElementsToShow - 1) + ')').hide();
                }
            }); 
        }
    }
};

module.exports = Utilites;