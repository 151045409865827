'use strict';

let box = document.querySelector('.animation-banner');
let width = box && box.offsetWidth ? box.offsetWidth*0.26+"px" : '10px';
var useMobileView = $(window).outerWidth() <= 768;

if ($('.js-animation-banner').length) {
    $('.js-animation-banner').css('visibility', 'visible');
    if (useMobileView) {
        var bannerAnimationHeight = $('.top-animation-banner').height() || 0;
        var headerAnimationHeight = $('.menu-bottom-container').height();
        $('.js-animation-banner').parents('header').addClass('has-banner');
        $(".HPrevamp-header #menus-container .menu-mobile .right-menu-list #nav-mobile-container, .HPrevamp-header #menus-container .navbar-top-categories .nav-item .dropdown-menu").not('.pd-nav-mobile-container').css('top', bannerAnimationHeight + headerAnimationHeight + 'px');
    }
    $('.js-animation-banner').slick({
        slidesToShow: 1,
        centerMode: true,
        arrows: false,
        swipe: false,
        accessibility: false,
        infinite: true,
        initialSlide: 0,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 3000,
        centerPadding: width,
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                accessibility: false,
                centerMode: true,
                centerPadding: width,
                initialSlide: 0
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                accessibility: false,
                centerPadding: '0px',
                initialSlide: 0,
                arrows: false,
                swipe: true,
                infinite: true,
            }
        }]
    });

    $('#control-top-animation, #control-inside-animation').on('click', function() {
        if ($(this).attr('data-pause') == 'pause'){
            $(this).parents('.top-animation-content').find('.js-animation-banner').slick('slickPause');
            $(this).attr('data-pause','play');
        } else {
            $(this).parents('.top-animation-content').find('.js-animation-banner').slick('slickPlay');
            $(this).attr('data-pause','pause');
        }  
    });
}

$('.js-top-animation-banner').slick({
    slidesToShow: 1,
    initialSlide: 0,
    centerMode: false,
    arrows: false,
    swipe: false,
    accessibility: false,
    infinite: true,
    swipeToSlide: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 10000,
    centerPadding: width,
    pauseOnFocus: false,
    pauseOnHover: false,
    focusOnSelect: false,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
            accessibility: false,
            centerMode: true,
            centerPadding: width,
            variableWidth: true
        }
    },
    {
        breakpoint: 768,
        settings: {
            centerMode: true,
            accessibility: false,
            centerPadding: '0px',
            initialSlide: 0,
            arrows: false,
        }
    }]
});

// Manually refresh positioning of slick
$(".animation-banner").find(".js-animation-banner").slick('setPosition');

// if values of speed and duration are not set or they are 0,
// use default values from slick slider init function

if ($(".js-animation-duration").length) {
    const value = parseFloat($(".js-animation-duration").text()).toFixed(1);
    if (value > 0) {
        $(".js-animation-banner").slick('slickSetOption', 'autoplaySpeed', value*1000);
    }
}

if ($(".js-animation-speed").length) {
    const value = parseFloat($(".js-animation-speed").text()).toFixed(1);
    if (value > 0) {
        $(".js-animation-banner").slick('slickSetOption', 'speed', value*1000);
    }
}

if ($(".js-top-animation-duration").length) {
    const value = parseFloat($(".js-top-animation-duration").text()).toFixed(1);
    if (value > 0) {
        $(".js-top-animation-banner").slick('slickSetOption', 'autoplaySpeed', value*1000);
    }
}

if ($(".js-top-animation-speed").length) {
    const value = parseFloat($(".js-top-animation-speed").text()).toFixed(1);
    if (value > 0) {
        $(".js-top-animation-banner").slick('slickSetOption', 'speed', value*1000);
    }
}

if ($("#menus-container .js-top-animation-banner").length) {
    const topBannerHeight = $("#menus-container .js-top-animation-banner").height();
    const headerHeight = $('.menu-bottom-container').height();
    $("#nav-mobile-container, #menu-nav-mobile .dropdown-menu").not('.pd-nav-mobile-container').css('top', headerHeight + topBannerHeight + 'px');
}

$(window).on('resize', function () {
    $('.js-animation-banner').slick('setPosition');
})