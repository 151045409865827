'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 2;

$(document).ready(function () {
    $('body').addClass('mouse-navigation');
    $(this).on('keydown', function () {
        $('body').removeClass('mouse-navigation');
    })
    $(this).on('click', function () {
        $('body').addClass('mouse-navigation');
    })
    if ($('.vto-no-header').length) {
        $('body').addClass('body-no-header');
    }
});

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).parents('.custom-search').find('.suggestions-wrapper');
}


/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('header').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'icon-SEARCH';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {ObprocessResponseject|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();
    $(this).siblings('.reset-button').addClass('d-sm-block');
    $.spinner().stop();
    if (typeof (response) !== 'object') {
        $suggestionsWrapper.append(response).fadeIn();
        positionSuggestions(this);
        if (isMobileSearch(this)) {
            toggleSuggestionsIcon('close');
            applyModals(this);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }

        suggestionsList = $('.suggestions .product-view-suggestion');
        var suggestionProductFound = $('#product-grid-suggestion').attr('data-search-alert');
        if ($(suggestionsList).length > 0) {
            $('.search-alert').html($(suggestionsList).length+ " " +suggestionProductFound);
        }

        $('#no-result-search').text($('.search-field').val());
    } else {
        $suggestionsWrapper.fadeOut();
    }
    $('body').trigger('UpdateLoadImage');
}


/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {   
    if ($(scope).val().length >= minChars) { 
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        $(this).siblings('.reset-button').addClass('d-sm-block');
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
}


module.exports = function () {

    $('.custom-search-button').on('click', function () {
        $('input.search-field').val('');
        $('.ico-search-mobile').attr('aria-expanded', 'false');
    });

    $('#search-desktop-area').on('shown.bs.collapse', function () {
        $('#search-desktop-area .search-field').trigger('focus');
    });

    if ($(".hp-revamp-search").length) {
        $(".suggestion-results-info .left-side-block .text-left-side").addClass("left-side-second-view");

        $(".hp-revamp-search").on("focusin", function() {
            $(".custom-search").addClass("is-search-focused");
            $(".search-header").addClass("is-focused");
            $(".text-left-side").addClass("left-side-second-view");
            $(".search-input").addClass("input-search-hp-revamp");
        });
    
        $(".hp-revamp-search").on("focusout", function() {
            if ($(this).val() === "") {
                $(".custom-search").removeClass("is-search-focused");
                $(".search-header").removeClass("is-focused");
                $(".text-left-side").removeClass("left-side-second-view");
                $(".search-input").removeClass("input-search-hp-revamp");
            }
        });
    }


    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup', function () {
            var searchValue = $(this).val();
            $('.hp-revamp-search-info').html(searchValue);
        });
        $(this).on('keyup click', function (e) {
            if(this.value.length >= minChars) {
                debounceSuggestions(this, e);
                $('.search-asset , .search-slot').hide();
            } else {
                $('.search-asset , .search-slot').show();
            }
        });
        $(this).on('keypress', function (e) {
            if(this.value.length < 2) {
                if (e.keyCode === 13 || e.which === 13) {
                    e.preventDefault();
                    return false;
                }
            }

        });
    });

    $('body').on('click', function (e) {
        if (!$('.suggestions').has(e.target).length && !$(e.target).hasClass('search-field')) {
            $('.suggestions').fadeOut();
            $('.search-asset , .search-slot').show();
        }
    });
   
    $('.homepage-product-element:last').on('focusout', function () { 
        $('.search-field').trigger('focus');
    });

    $('.back-to-top-button').on('click', function () {
        $('#top').trigger('focus');
    });

    $('.ico-search-mobile').on('click', function () {
        $('.alert-product-stock-btn').toggleClass('d-none');
    });

    $('.modal-header .close').on('click', function() {
        $('#quickViewModal').modal('hide');
    });
};
