'use strict';

var base = require('base/product/base');
var MicroModal = require('../lib/micromdal-ie');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
base.getPidValue = function ($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text() || $($el).closest('.product-detail').data('pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length ?
        $($el).closest('.product-detail').find('.quantity-select') :
        $('.quantity-select');
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
base.appendToUrl = function (url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
base.updateCartTotals = function (data) {

    if (data.queryString) {
        var id = data.queryString.split('&') ? data.queryString.split('&') : '';
        var product = id[0].split('=') ? id[0].split('=') : '';
        var selectedProduct = product[1] ? product[1] : '';
    }

    // set shipping cost in cart
    var isFreeShipping;
    var isFREEtext = $('.shipping-cost-for-free') ? $('.shipping-cost-for-free').text() : "";
    if (data.totals.subtotalvalue >= data.totals.offeredShippingMethodthreshold && data.shipments[0].selectedShippingMethod == data.offeredShippingMethod) {
        $('.shipping-cost').empty().html("<div class='bonus-product-bloc'><div class='bonus-produc-bloc-icon'></div><div class='bonus-produc-bloc-text'>" + isFREEtext + "</div></div>");
        isFreeShipping = true;
    } else {
        $('.shipping-cost').empty().append(data.totals.totalShippingCost);
        isFreeShipping = false;
    }

    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    if ($(".klarna-pdp").length) {
        $('.klarna-pdp').attr('data-purchase-amount', parseFloat(data.totals.grandTotalValue*100).toFixed(0));
        window.Klarna.OnsiteMessaging.refresh();
    }

    if (selectedProduct) {
        data.items.forEach(function (item) {
            if (item.id == selectedProduct) {
                $('.remove-line-item-button[data-pid="' + selectedProduct + '"]').data('price', item.priceTotal.price);
                $('.remove-line-item-button[data-pid="' + selectedProduct + '"]').data('nonadjustedprice', item.priceTotal.nonAdjustedPrice);
                $('.remove-line-item-button[data-pid="' + selectedProduct + '"]').data('qty', item.quantity);
            }
        });
    }


    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0 && !isFreeShipping) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
        $('.shipping-delivery-price').addClass('FREE-shipping-discount');
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
        $('.shipping-delivery-price').removeClass('FREE-shipping-discount');
    }
    if (isFreeShipping) {
        $('.shipping-delivery-price').addClass('FREE-shipping-discount');
    }

    data.items.forEach(function (item) {
        $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

base.updateSamplesNumber = function (data) {
    $('.number-items-samples').empty().html(data.resources.numberOfItemsMsg);
    $('.number-items-samples').append('<span aria-hidden="true" title="icon basket" class="icon icon-chevron-thin-right"></span>')
    $('#NumberSamples').empty().html(data.numberOfSamples);
    $('.samples-list .sample-input').each(function () {
        $(this).removeAttr('disabled');
        $(this).parents('.samples-cart-element').removeClass('sample-disabled');
        // Only uncheck if necessary
        if ($(this).prop('checked')) {
            $(this).prop('checked', false);
            $(this).trigger("change");
        }
    });
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
base.updateApproachingDiscounts = function (approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
    $("button[data-micromodal-trigger='removeCouponModal']").click(function () {
        MicroModal.show('removeCouponModal');
    });
    $("#removeCouponModal button[data-micromodal-close]").click(function () {
        MicroModal.close('removeCouponModal');
    });

}


/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */

base.updateAvailability = function (data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}


/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
base.validateBasket = function (data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart .popover').empty();
            $('.minicart .popover').hide();
            $('.mini-cart-overlay').hide();
        }

        
    }
}


/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
base.createErrorNotification = function (message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    if ($('.add-to-cart').is('[disabled]')) {
        $('.product-breadcrumb').addClass('product-breadcrumb-top');
    } else {
        $('.product-breadcrumb').removeClass('product-breadcrumb-top');
    }

    $productContainer.find('.color-swatchs-palette-container .selected').removeClass('selected');

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        if (attrValue.selected) {
            $attrValue.addClass('selected');
            attrValue.selected = true;
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            attrValue.selected = false;
        }
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processNonSwatchValues(attr, $productContainer, msgs) {
    var $defaultOption = $productContainer.find('#pdp-capacity-selector' + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $('#pdp-capacity-selector' + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('href', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
        if ($('#capacitymodify')) {
            if (attrValue.selected) {
                $attrValue.addClass('selected');
                $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            } else {
                $attrValue.removeClass('selected');
                $attrValue.siblings('.selected-assistive-text').empty();
            }
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {

        if (attrsWithSwatches.indexOf(attr.id) > -1 && attr.id === 'color') {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer, msgs);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<div>' + response.resources.info_selectforstock + '</div>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<div>' + message + '</div>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getProductAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attribute, i) {
        var attributeResult = attribute.value;

        if (i !== (attributes.length - 1)) {
            attributeResult = attributeResult + ', ';
        }
        html += attributeResult;
    });

    return html;
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': ' +
                    attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isPDPrevamp = $('.newPDP-container').length > 0 ? true : false;
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images;
    primaryImageUrls.large.forEach(function (imageUrl, idx) {
        $productContainer.find('.primary-images').find('img').eq(idx)
            .attr('src', imageUrl.url);
    })

    primaryImageUrls.small.forEach(function (imageUrl3, idx) {
        $productContainer.find('.primary-images .navigation-slider-pdp').find('img').eq(idx)
            .attr('src', imageUrl3.url);
    });

    primaryImageUrls.small.forEach(function (imageUrl3, idx) {
        $productContainer.find('#sync2').find('img').not('.video_thumbnail img').eq(idx)
            .attr('src', imageUrl3.url);
    });

    if ($('.set-item').length) {
        // Ignore rest of code for product set
        return;
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        if (!isPDPrevamp){
            var $priceSelector = $('.prices .price', $productContainer).length ?
                $('.prices .price', $productContainer) :
                $('.prices .price');
            $priceSelector.replaceWith(response.product.price.html);
            $('.prices .price').append('<div class="price-per-liter d-none">' + response.product.pricePerUnit + '</div>');
            // Update sticky price on old PDP
            $('.real-price').html(response.product.price.sales.formatted);
        } else {
            if ($("#pdp-sticky-wrapper .add-to-cart .price").length > 0) {
                $("#pdp-sticky-wrapper .add-to-cart .price, .newPDP-container .add-to-cart .price").replaceWith(response.product.price.html2);
            } else {
                $("#pdp-sticky-wrapper .add-to-cart, .newPDP-container .add-to-cart").append(response.product.price.html2);
            }
        }
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product,
            $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));

    //Update MUFE product attributes

    $('.product-attributes').empty().html(getProductAttributesHtml(response.product.allAttributes));
 
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    var optionsHtml;
    if (!($productContainer.parent('.bonus-product-item').length > 0) && quantities.length > 0) {
        optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    } else {
        optionsHtml = () => {
            return '<option value="1"  data-url="" selected>' + 1 + '</option>';
        }
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * Update Harry Up! Alert 
 */

function updateHarryUpAlert(data) {
    if (data.product.harryUp && data.product.readyToOrder && data.product.available && data.product.productLeftNbr) {
        $('.harry-ups').show();
        $('.harry-ups').html('<span class="icon-ALERT"></span><p>'+data.resources.productsLeftNbr+'</p>');
    } else {
        $('.harry-ups').hide();
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Update In Stock! Alert 
 */

function updateInStockAlert(data) {
    if (!data.product.minStock && data.product.readyToOrder && data.product.available) {
        $('.in-stock').show();
        $('#pdp-quantity-selector .quantity-select').removeClass('outofstock').removeAttr('disabled');
        $('.pdp-qty-selector-label').removeClass('outofstock');
        $("#pdp-sticky-wrapper .add-to-cart .price, .newPDP-container .add-to-cart .price").show();
        $('.prices-add-to-cart-actions').removeClass('outofstock');
        $('.deliveryDateEstimation-findinstore').show();
    } else {
        $('.in-stock').hide();
        $('#pdp-quantity-selector .quantity-select').addClass('outofstock').attr('disabled', true);
        $('.pdp-qty-selector-label').addClass('outofstock');
        $("#pdp-sticky-wrapper .add-to-cart .price, .newPDP-container .add-to-cart .price").hide();
        $('.prices-add-to-cart-actions').addClass('outofstock');
        $('.deliveryDateEstimation-findinstore').hide();
    }
}

function updateStampDisplay(product) {
    var $productStampIcon = $(".product_stamp_icon");

    if (product.imageStampVariant && !$productStampIcon.length) {
        $(".js-image-stamp").prepend("<div class='product_stamp_icon' style='background-image: url(" + product.imageStampVariant + ");'></div>")
    } else if (!product.imageStampVariant && $productStampIcon.length) {
        $productStampIcon.remove();
    }
}

function updateBadgeDisplay(product) {
    var $productBadge = $(".pdp-badge");

    if (product.productTag && !$productBadge.length) {
        $(".promo-name-badge").append("<div class='pdp-badge'>" + product.productTag + "</div>")
    } else if (!product.productTag && $productBadge.length) {
        $productBadge.remove();
    } else if (product.productTag && $productBadge.length) {
        $productBadge.text(product.productTag);
    }
}

/**
 * 
 * @param {Object} product - Product object
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function updateSetInformation(product, $productContainer) {
    if ($('.set-item').length) {
        // Update set id & avaibility
        $productContainer.find('.tile-image').attr('src', product.images.large[0].url);
        $productContainer.attr('data-pid', product.id);
        $productContainer.attr('data-avaibility', !product.minStock && product.readyToOrder && product.available);
        // Update add to cart button
        updateSetAddToCartBtn();
    }
}

$(document).on("click",".set-item .color-swatchs-palette-container .shade-selector-button", function () {
    if ($(this).find('.strock-line').length) {
        $(this).closest('.set-item').find('.not-available').removeClass('d-none');
        $(this).closest('.set-item').find('.not-available').addClass('d-block');
    } else {
        $(this).closest('.set-item').find('.not-available').addClass('d-none');
        $(this).closest('.set-item').find('.not-available').removeClass('d-block');
    }
});

function updateSetAddToCartBtn() {
    if ($('.set-item[data-avaibility=false]').length === 0) {
        // All items are available
        $('.add-to-cart-global').removeAttr('disabled');
        $('.kit-unavailable-msg').addClass('d-none');
    } else {
        // At least one item is not available
        $('.add-to-cart-global').attr('disabled', 'disabled');
        $('.kit-unavailable-msg').removeClass('d-none');
    }
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {updateAddToCartStatus[]} product -  The number of products to purchase
 */

function updateAddToCartStatus(data) {
    var $isNewPDP = $('.newPDP-container').length > 0 ? true : false;
    var $buttonform = $('.create-alert .alert-product-stock-btn');
    if (data.product.available && !data.product.minStock && data.product.readyToOrder) {
        if ($isNewPDP) {
            if ($(".cart-and-ipay .add-to-cart .add-to-cart-divider").length > 0) {
                $(".cart-and-ipay .add-to-cart .add-to-cart-divider").html(data.resources.inStock);
            } else {
                $(".cart-and-ipay .add-to-cart").prepend('<span class="add-to-cart-divider">' + data.resources.inStock + '</span>');
            }

        } else {
            $(".cart-and-ipay .add-to-cart").html(data.resources.oldInStock);
        }
        $('.noMoreAvailableForSale').hide();
        $(".cart-and-ipay .add-to-cart ").removeAttr('disabled');
        $('.create-alert-wrapper').hide();
        $('#quantity-plus').removeAttr('disabled');
        $('.deliveryDateEstimation-findinstore').removeClass('hidden');
    } else {
        var datasubmited = $buttonform.attr('data-submited');
        var dataid = data.product.id;

        if (datasubmited != undefined) {
            if (datasubmited.indexOf(dataid) != -1) {
                $buttonform.prop('disabled', true);
            } else if (datasubmited.indexOf(dataid) == -1) {
                $buttonform.prop('disabled', false);
            }
        }
        if ($isNewPDP) {
            if ($(".cart-and-ipay .add-to-cart .add-to-cart-divider").length > 0) {
                $(".cart-and-ipay .add-to-cart .add-to-cart-divider").html(data.resources.inStock);
            } else {
                $(".cart-and-ipay .add-to-cart").prepend('<span class="add-to-cart-divider">' + data.resources.inStock + '</span>');
            }
            $(".cart-and-ipay .add-to-cart .add-to-cart-divider").html(data.resources.outOfStock);
        } else {
            if (data.product.noMoreAvailableForSale){
                $(".cart-and-ipay .add-to-cart").html(data.resources.nomoreavailable);
            } else {
                $(".cart-and-ipay .add-to-cart").html(data.resources.outOfStock);
            }
        }
        if (data.product.noMoreAvailableForSale){
            $('.productNoMoreAvailableForSale').val(data.product.noMoreAvailableForSale && data.product.minStock);
            $(".cart-and-ipay .add-to-cart .add-to-cart-divider").html(data.resources.nomoreavailable);
            $('.noMoreAvailableForSale').removeClass('hidden');
            $('.noMoreAvailableForSale').show();
            $('.create-alert').hide();
            $('.invalid-feedback').hide();
            if ($(window).width() < 479){
                $('.noAvailableAlertMobile').removeClass('hidden');
            }else {
                $('.noAvailableAlertDesktop').removeClass('hidden');
                $('.noAvailableAlertMobile').addClass('hidden');
            }
        }else {
            $('.create-alert').show();
            $('.create-alert').removeClass('hidden');
            $('.noMoreAvailableForSale').hide();
        }
        $('.create-alert-wrapper').removeClass('hidden');
        $('.create-alert-wrapper').show();

        $(".cart-and-ipay .add-to-cart ").attr('disabled', 'disabled');
        
        $('.deliveryDateEstimation-findinstore').addClass('hidden');
        
        var variationAttribute = data.product.variationAttributes;
        variationAttribute.forEach(function (variation) {
            if (variation.attributeId === "color") {
                variation.values.forEach(function (attrValue) {
                    if (attrValue.selected) {
                        var labelAlertHTML = attrValue.CORE_shade_number + ' - ' + attrValue.displayValue;

                        if (attrValue.CORE_shade_informations && attrValue.CORE_shade_informations.length > 0) {
                            labelAlertHTML += ' - ' + attrValue.CORE_shade_informations;
                        }

                        $('#variation-label-alert').html(labelAlertHTML);
                        $('.out-of-stock-variant-label').val(labelAlertHTML);
                    }
                });
            }
        });
        $('.quantity-button').attr('disabled', true);

        $(".alertProductId").val(data.product.id);
        $('.out-of-stock-variant-id').val(data.product.id);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {

    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer
        });
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                if ($('.set-item').length) {
                    updateSetInformation(data.product, $productContainer);
                    updateAddToCartStatus(data);
                } else {
                    updateOptions(data.product.optionsHtml, $productContainer);
                    updateQuantities(data.product.quantities, $productContainer);
                    updateAddToCartStatus(data);
                    updateHarryUpAlert(data);
                    updateInStockAlert(data);
                    updateStampDisplay(data.product);
                    updateBadgeDisplay(data.product);
                }

                $('body').trigger('product:afterAttributeSelect', {
                    data: data,
                    container: $productContainer
                });

                if ($('.productDataGTM').length > 0) {
                    var productDataGTM = $('.productDataGTM').val();
                    productDataGTM = JSON.parse(productDataGTM);
                    var variantName = '';
                    var productData= data.product.variantList;
                    for (let index = 0; index < productData.length; index++) {
                        const element = productData[index];
                        if (element.prodID == data.product.id) {
                            variantName = element.customDescription;
                            break;
                        }
                    }
                    dataLayer.push({
                        'event':'product_page',
                        'ecommerce': {
                            'detail': {
                                'products': [{
                                    name: data.product.productName,
                                    id: data.product.id,
                                    price: data.product.price.sales.value,
                                    brand: data.product.brand,
                                    category: productDataGTM['category'],
                                    variant: variantName,
                                    dimension17: productDataGTM['dimension17']
                                }]
                            }
                        },
                    });
                }        
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" role="dialog">' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="' + data.maxBonusItems + '"' +
        'data-UUID="' + data.uuid + '"' +
        'data-pliUUID="' + data.pliUUID + '"' +
        'data-addToCartUrl="' + data.addToCartUrl + '"' +
        'data-pageStart="0"' +
        'data-pageSize="' + data.pageSize + '"' +
        'data-moreURL="' + data.showProductsUrlRuleBased + '"' +
        'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <span class="">' + data.labels.selectprods + '</span>' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">&times;</button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'html',
        success: function (html) {
            var parsedHtml = parseHtml(html);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return {
        body: body,
        footer: footer
    };
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

base.addToCart = function () {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.set-item').each(function () {
                setPids.push({
                    pid: $(this).data('pid'),
                    qty: $('#quantity').val(),
                    options: getOptions($(this))
                });
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = base.getPidValue($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }

        addToCartUrl = getAddToCartUrl();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: getChildProducts(),
            quantity: base.getQuantitySelected($(this))
        };

        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    base.miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

base.colorAttribute = function () {
    $(document).on('click', '[data-attr="color"] .link-pdp-color,[data-attr="color"] .link-pdp-color-2, ul.custom-dropdown-menu .custom-dropdown-capacity, div.dropdownMenuButton .custom-dropdown-menu-button', function (e) {

        e.preventDefault();

        if ($(this).hasClass('shade-selector-button')){
            e.currentTarget.href = $(this).attr('data-href');
        } else if ($(this).hasClass('custom-dropdown-menu-button')){
            e.currentTarget.href = $(this).attr('data-url');
        }

        if ($(this).attr('disabled') || $(this).find('span').hasClass('selected')) {
            return;
        }
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        if (!$productContainer.length) {
            $productContainer = $(this).closest('main').find('.product-detail');
        }

        attributeSelect(e.currentTarget.href, $productContainer);
    });
};

base.enableBonusProductSelection = function () {
    $('body').on('bonusproduct:updateSelectButton', function (e, response) {
        $('button.select-bonus-product', response.$productContainer).attr('disabled',
            (!response.product.readyToOrder || !response.product.available || response.product.minStock));
        var pid = response.product.id;
        $('button.select-bonus-product', response.$productContainer).data('pid', pid);
    });
};

base.selectSize = function () {
    $(document).on('click', 'option.capacity-link' , function () {
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        if (!$productContainer.length) {
            $productContainer = $(this).closest('main').find('.product-detail');
        }
        attributeSelect($(this).attr('href'), $productContainer);
    });
};


base.basketQuantityChange = function () {
    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var bonusTemplate = $('#cart-page-container') && $('#cart-page-container').length > 0 ? "isCartBonusProduct" : "isMiniCartBonusProduct";

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
            bonusTemplate: bonusTemplate
        };
        url = base.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {

                try {
                    if (data.productUpdateGtm) {
                        dataLayer.push(data.productUpdateGtm);
                    }
                } catch (e) {
                    console.log('Unable to trigger product update GTM data');
                }


                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                base.updateCartTotals(data);
                base.updateSamplesNumber(data);
                base.updateApproachingDiscounts(data.approachingDiscounts);
                base.updateAvailability(data, uuid);
                base.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                var selector = $('ul.productlist-quantity');
                var bonusProduct = $('.bonus-product-line-item');
                $(bonusProduct).remove();
                $(selector).append(data.bonusHTML);
                
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    base.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });
}

base.basketRemoveProduct = function () {
    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();
        var buttonElement = $(this);
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = base.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                try {
                    if (data.productUpdateGtm) {
                        dataLayer.push(data.productUpdateGtm);
                    }
                    if (data.bambuserOTOCartUpdate) {
                        var basketProductLineItems = JSON.parse(window.localStorage.getItem('basketProductLineItems')) || {};
                        basketProductLineItems.remove(data.bambuserOTOCartUpdate.sku);
                        window.localStorage.setItem('basketProductLineItems', JSON.stringify(basketProductLineItems));
                    }
                } catch (e) {
                    console.log('Unable to trigger product remove GTM push');
                }

                if (data.basket.numItems === 0) {
                    $('.popover').hide();
                    document.location.reload(true);
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    base.updateCartTotals(data.basket);
                    base.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    base.validateBasket(data.basket);
                    buttonElement.closest('.modal-content').find('.close').trigger('click');
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    base.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
}

base.addBonusProductsToCart = function () {
    $(document).on('click', '.add-bonus-products', function () {
        var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
        var queryString = '?pids=';
        var url = $('.choose-bonus-product-dialog').data('addtocarturl');
        var pidsObject = {
            bonusProducts: []
        };

        $.each($readyToOrderBonusProducts, function () {
            var qtyOption =
                parseInt($(this)
                    .data('qty'), 10);

            var option = null;
            if (qtyOption > 0) {
                if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                    option = {};
                    option.optionId = $(this).data('optionid');
                    option.productId = $(this).data('pid');
                    option.selectedValueId = $(this).data('option-selected-value');
                }
                pidsObject.bonusProducts.push({
                    pid: $(this).data('pid'),
                    qty: qtyOption,
                    options: [option]
                });
                pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
            }
        });
        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
        queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
        $.spinner().start();
        $.ajax({
            url: url + queryString,
            method: 'POST',
            success: function (data) {
                $.spinner().stop();
                if (data.error) {
                    $('#chooseBonusProductModal').modal('hide');
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                } else {
                    $('.configure-bonus-product-attributes').html(data);
                    $('.bonus-products-step2').removeClass('hidden-xl-down');
                    $('#chooseBonusProductModal').modal('hide');

                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.minicart-quantity').html(data.totalQty);
                    $('.add-to-cart-messages').html(
                        '<div class="alert alert-success add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.msgSuccess + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                        if ($('.cart-page').length) {
                            location.reload();
                        }
                    }, 1500);
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

module.exports = base;