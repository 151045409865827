var util = require('../components/util');

$(function () {
    var $moreButton = $("#colorSelector-more");
    var $closeButton = $("#shadeSelector .close");
    var $applyButton = $("#selectshade");

    var $pdpcontent = $(".product-detail #primary-content");
    var $shadecontent = $(".product-detail #shadeSelector");
    var $primaryimage = $(".product-detail .primary-images");

    // Variations attributes functions

    function showShadeSelector(){
        $pdpcontent.hide();
        $shadecontent.fadeIn('slow');
        window.scrollTo(0, 0);

        if (util.isMobile() || $(window).width() < 992) {
            $primaryimage.hide();
        }
    }

    function removeShadeSelector(){
        $pdpcontent.fadeIn('slow');
        $shadecontent.hide();
        window.scrollTo(0, 0);

        if (util.isMobile() || $(window).width() < 992) {
            $primaryimage.fadeIn('slow');
        }
    }

    $moreButton.click(function () {
        showShadeSelector();
        $closeButton.focus();
    });

    $applyButton.click(function () {
        removeShadeSelector();
        $moreButton.focus();
    });

    $closeButton.click(function () {
        removeShadeSelector();
        $moreButton.focus();
    });

    $(".current-color").hover(function () {
        $('.custom-dropdown-trigger').trigger('hover');
    });

    $(".current-color").click(function () {
        $(this).addClass('fadeInDown animated faster');
    });

    $(".tintSelector-icone").click(function() {
        $(this).parents('.custom-dropdown').find(".custom-dropdown-trigger").trigger('click');
    });

    $("#colorSelector-nbr").click(function () {
        $(".color-swatchs-palette").css("overflow","visible").addClass('GR-h-auto');
        $(".colorSelector-open").css("display", "none");
    });

    $("#colorSelector-close").click(function () {
        $(".color-swatchs-palette").css("overflow","hidden").addClass("h25");
        $(".color-swatchs-palette").removeClass('GR-h-auto');
        $(".colorSelector-open").css("display", "flex");
    });
});