'use strict';

module.exports = function () {
    var BackToTopBtn = $('.back-to-top-button');

    $(document).ready(function () {
        var bannerExists, probannerExists, stickyNav;
        if (!($('header').hasClass('HPrevamp-header'))) {
            bannerExists = $('.navbar-promo').find('.navbar-promo-content')[0];
            probannerExists = $('.ico-pro-mobile')[0];
            stickyNav = function () {
                var header = $('header');
                var scrollTop = $(window).scrollTop(); // our current vertical position from the top

                // if we've scrolled more than the navigation, change its position to fixed to stick to top,
                // otherwise change it back to relative
                var windowSize = $(window).width();

                if ($('.add-to-cart').is('[disabled]') && windowSize < 1200) {
                    $('.product-breadcrumb').addClass('product-breadcrumb-top');
                } else {
                    $('.product-breadcrumb').removeClass('product-breadcrumb-top');
                }

                if (scrollTop > 1) { /* add sticky class to menu and hide/show unwanted elements */
                    if (!header.hasClass('sticky')) {
                        $('.banner-black-home').css('padding-top', '63px');
                        $('.navbar-top-header').hide();
                        $('.menu-sticky').css('display', 'flex');
                        $('.search-bloc').hide();
                        $('.ico-search-menu .site-search-top-header').hide();
                        $('.navbar-promo').hide();
                        $('.logo-top-header').hide();
                        $('.logo-top-header-small').show();

                        if (bannerExists === undefined && windowSize > 960) {
                            $('.dropdown-menu').css('top', '93px');
                            $('#pdp-sticky-wrapper').css('top', '93px');
                        } else {
                            $('.dropdown-menu').css('top', '59px');
                            $('#pdp-sticky-wrapper').css('top', '60px');
                        }
                        $('#menus-container').addClass('menu-sticky-container');
                        // $('.minicart-label-bag').hide();
                        $('.minicart-label-bag-sticky').show();
                        $('.menu-sticky-container .ico-search-sticky .site-search-top-header').show();
                        header.addClass('sticky');
                        header.removeClass('noSticky');
                        $('.minicart-quantity').addClass('minicart-quantity-sticky');
                        if (windowSize < 1200) {
                            if (probannerExists !== undefined) {
                                $('.alert-product-stock-btn').css('top', '88px').show();
                            } else {
                                $('.alert-product-stock-btn').css('top', '75px').show();
                            }
                        
                            $('.minicart .popover').css('width', 'unset;');
                            $('.menu-sticky').hide();
                            if ($('#menu-nav-mobile').hasClass('show')) {
                                header.removeClass('sticky');
                                header.addClass('noSticky');
                            } else {
                                header.addClass('sticky');
                                $('.banner-black-home').css('padding-top', '63px');
                                header.removeClass('noSticky');
                            }
                        }
                    }
                } else {
                    header.removeClass('sticky');
                    header.addClass('noSticky');
                    $('.banner-black-home').css('padding-top', '0px');
                    $('.minicart-quantity').removeClass('minicart-quantity-sticky');
                    $('.navbar-top-header').show();
                    $('.menu-sticky').hide();
                    $('.logo-top-header').show();
                    $('.logo-top-header-small').hide();
                    $('.navbar-promo').show();
                    $('.search-bloc').show();
                    $('.ico-search-menu .site-search-top-header').css('display', 'flex');
                    $('.menu-sticky-container .ico-search-sticky .site-search-top-header').hide();
                    $('.dropdown-menu').css('top', '150px');
                    $('#menus-container').removeClass('menu-sticky-container');
                    $('.minicart-label-bag').show();
                    if (windowSize < 1200){
                        if (bannerExists !== undefined) {
                            $('.alert-product-stock-btn').css('top', '59px').hide();
                        } else if (probannerExists !== undefined) {
                            $('.alert-product-stock-btn').css('top', '88px').show();
                        } else {
                            $('.alert-product-stock-btn').css('top', '75px').show();
                        }
                        $('.navbar-top-header').hide();
                        $('.ico-search-menu .site-search-top-header').hide();
                        $('.search-bloc').hide();
                        (bannerExists === undefined) ? $('.navbar-promo').hide() : $('.navbar-promo').css('display','flex'); 
                    }
                }
            };

            stickyNav();
            // and run it again every time you scroll
            $(window).scroll(function () {
                stickyNav();
            });

            $('#login-button-mobile').on('click touch', function () {
                $('#register-button-mobile').attr('aria-expanded', false);
                $('#accordion-panel-2').removeClass('show in');
            });

            $('#register-button-mobile').on('click touch', function () {
                $('#login-button-mobile').attr('aria-expanded', false);
                $('#accordion-panel-1').removeClass('show in');
            });

            if (window.location.href.indexOf("#register-button-mobile") > -1) {
                $('#login-button-mobile').trigger("click");
                $('#register-button-mobile').trigger("click");
            }

            $(window).scroll(function () {
                $('.dropdown-menu').hide();
                $('.dropdown-menu.active').removeClass('active');
                $('.dropdownPCIS button').removeClass('active tab-navigation show-menu');
            });
        } else {
            bannerExists = $('.navbar-promo').find('.navbar-promo-content')[0];
            probannerExists = $('.ico-pro-mobile')[0];
            stickyNav = function () {
                var header = $('header');
                var scrollTop = $(window).scrollTop(); // our current vertical position from the top

                // if we've scrolled more than the navigation, change its position to fixed to stick to top,
                // otherwise change it back to relative
                if (scrollTop > 1) { /* add sticky class to menu and hide/show unwanted elements */
                    if (!header.hasClass('sticky')) {
                        header.addClass('sticky');
                        header.removeClass('noSticky');
                    }
                } else {
                    header.removeClass('sticky');
                    header.addClass('noSticky');
                }
            }
            $('.HPrevamp-header .dropdown-menu-close-container').on('click', function() {
                $(this).closest('.nav-item').children('.toggler-icon').removeClass('icon-minus').addClass('icon-PLUS');
                $(this).closest('.nav-item').find('.first-block').removeClass('active');
            });

            $('.HPrevamp-header .dropdown-menu-close-container').on('click', function() {
                $('.toggler-icon').removeClass('icon-minus').addClass('icon-PLUS');
                $('.dropdown-menu').hide();
                $('#alert-product-stock').show();

                var bannerExists = $('.navbar-promo').find('.navbar-promo-content')[0];
                var probannerExists = $('.ico-pro-mobile')[0];
                var windowSize = $(window).width();

                if (windowSize < 1200){
                    if (bannerExists !== undefined) {
                        $('.alert-product-stock-btn').css('top', '59px').hide();
                    } else if (probannerExists !== undefined) {
                        $('.alert-product-stock-btn').css('top', '88px').show();
                    } else {
                        $('.alert-product-stock-btn').css('top', '75px').show();
                    }
                }
            });

            $('.HPrevamp-header .mobile-menu-button').on('click', function() {
                $('.toggler-icon').removeClass('icon-minus').addClass('icon-PLUS');
                $('.country-selector .dropdown-menu').removeClass('active');
                $('.dropdown-menu').hide();
            });

            $('.dropdown-menu-close-container').on('click', function() {
                $(this).closest('.dropdown-menu').hide();
                $('#menu-nav-mobile').removeClass('display-menu').removeClass('show');
                $('#nav-mobile-container').removeClass('fixed-menu');
                $('.iconToggleContainer').removeClass("d-none");
                $('html, body').css({'overflow': 'auto'});
            });

            $('.HPrevamp-header .dropdown-menu-back-container, .icon-return-back').on('click', function() {
                $(this).closest('.nav-item').children('.toggler-icon').removeClass('icon-minus').addClass('icon-PLUS');
                $(this).closest('.nav-item').find('.first-block').removeClass('active');
                $(this).closest('.nav-item').children('.dropdown-menu').removeClass('fade-in-right active');
            });

            $('.HPrevamp-header .dropdown-menu-back-container, .icon-return-back').on('click', function() {
                $(this).closest('.dropdown-menu').hide();
            });

            $('.HPrevamp-header .new-country-selector-first').on('click', function() {
                $(this).closest('.nav-item').find('.icon-minus').removeClass('icon-minus').addClass('icon-PLUS');
            });

            stickyNav();
            // and run it again every time you scroll
            $(window).scroll(function () {
                $('.dropdown-menu').hide();
                $('.dropdown-menu.active').removeClass('active');
                $('.dropdownPCIS').removeClass('active tab-navigation show-menu');
                stickyNav();
            });
        }

        if ($('.page').attr('data-action') === 'Product-Show'){
            BackToTopBtn.attr('style', 'bottom: 183px !important');
        }
        if ($('.page').attr('data-action') === 'Cart-Show' && navigator.userAgent.match(/(iPad)/)){
            BackToTopBtn.attr('style', 'bottom: 156px !important');
        }

        $(window).scroll(function() {
            var showBackToTopIfNotSearch = $('.ico-search-mobile').attr('aria-expanded') === 'false';
            if ($(window).scrollTop() > 300 && showBackToTopIfNotSearch) {
                BackToTopBtn.fadeIn('slow');
            } else {
                BackToTopBtn.fadeOut('slow');
            }
        });
    });

    function animateScrollTop(target, duration) {
        duration = duration || 16;
        var scrollTopProxy = { value: $(window).scrollTop() };
        if (scrollTopProxy.value != target) {
            $(scrollTopProxy).animate(
                { value: target }, 
                {
                    duration: duration, step: function (stepValue) {
                        var rounded = Math.round(stepValue);
                        $(window).scrollTop(rounded);
                    }
                }
            );
        }
    }

    BackToTopBtn.on('click', function(e) {
        e.preventDefault();
        animateScrollTop(0,200);
    });
};