window.jQuery(function ($) {
    var imgNumber = $('.media-slider-pdp > .pdp-img-carousel').length;
    var windowSize = $(window).width();
    var MicroModal = require('../lib/micromdal-ie');
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("Trident");

    $('.country_selector_white').click( function() {
        $('.country_selector_white').removeClass('active');
        $(this).addClass('active');
    });
    
    if (msie > 0) {
        var ieModal = require('./micromdal-ie');
        ieModal.init({
            onShow: function (modal) {
                var modalcopy = $(modal);
                $('body').wrapInner('<div id="modal-background" aria-hidden="true"></div>');
                $('body').append(modalcopy);
                $('button.close').focus();
            },
            onClose: function (modal) {
                $('#modal-background').contents().unwrap();
                $('button[data-micromodal-trigger]').focus();
                $(modal).remove();

            },
            openTrigger: 'data-micromodal-trigger',
            closeTrigger: 'data-micromodal-close',
            disableScroll: false,
            disableFocus: true,
            awaitCloseAnimation: false,
            debugMode: true
        });
        ieModal.init({
            openTrigger: 'data-filter-trigger',
            closeTrigger: 'data-filter-close',
            disableScroll: true,
            disableFocus: true,
            awaitCloseAnimation: false,
            debugMode: true
        });
    } else {
        MicroModal.init({
            onShow: function (modal) {
                var modalcopy = $(modal);
                $('body').wrapInner('<div id="modal-background" aria-hidden="true"></div>');
                $('body').append(modalcopy);
                $(modal).find('button[data-micromodal-close]').trigger('focus');
            },
            onClose: function (modal) {
                var modalID = $(modal).attr('id');
                $('#modal-background').contents().unwrap();
                $('button[data-micromodal-trigger=' + modalID + ']').trigger('focus');
                $(modal).remove();
            },
            openTrigger: 'data-micromodal-trigger',
            closeTrigger: 'data-micromodal-close',
            disableScroll: false,
            disableFocus: true,
            awaitCloseAnimation: false,
            debugMode: true
        });

        MicroModal.init({
            openTrigger: 'data-filter-trigger',
            closeTrigger: 'data-filter-close',
            disableScroll: false,
            disableFocus: true,
            awaitCloseAnimation: false,
            debugMode: true
        });
    }

    $('.homepage-product-slider').slick({
        slidesToShow: 4,
        centerMode: true,
        centerPadding: '135px',
        arrows: true,
        swipe: false,
        infinite: true,
        initialSlide: 2,
        nextArrow: '<button class="next-arrow"><span class="icon-CHEVRON"></span></button>',
        prevArrow: '<button class="prev-arrow"><span class="icon-CHEVRON"></span></button>',
        swipeToSlide: true,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                centerMode: true,
                centerPadding: '100px',
                initialSlide: 0,
                arrows: true,
                swipe: true,
                infinite: true,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 3,
                centerMode: true,
                centerPadding: '87px',
                initialSlide: 1,
                arrows: false,
                swipe: true,
                infinite: true,
            }
        }]
    });
    $('.homepage-product-slider').show();

    // ADA add aria-label to dots on carousel
    $('.owl-carousel').each(function () {
        setTimeout(() => {
            $(this)
                .find('.owl-dot')
                .each(function (index) {
                    $(this).attr('aria-label', `Slide ${index + 1}`);
                });
        }, 500);
    });

    $('.pdp-product-slider').owlCarousel({
        items: 4,
        center: true,
        nav: true,
        touchDrag: true,
        loop: true,
        startPosition: 0,
        itemElement: "li",
        stageElement: "ul",
        navText: [
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            600: {
                items: 3,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
            },
            960: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
            },
            1400: {
                items: 4,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 20,
                nav: true,
                dots: false,
            }
        }
    });
    $('#sample-sum-slider').owlCarousel({
        items: 4,
        center: false,
        nav: false,
        dots: true,
        touchDrag: true,
        loop: false,
        startPosition: 0,
        itemElement: "li",
        stageElement: "ul",
        responsive: {
            0: {
                items: 3,
                loop: false,
                center: false,
                touchDrag: true,
                margin: 10,
                nav: true,
                dots: true,
            },
            600: {
                items: 3,
                loop: false,
                center: false,
                touchDrag: true,
                margin: 20,
                nav: true,
                dots: true,
            },
            960: {
                items: 3,
                loop: false,
                center: false,
                margin: 20,
                nav: true,
                dots: true,
            },
            1400: {
                items: 4,
                loop: false,
                center: false,
                margin: 20,
                nav: true,
                dots: true,
            }
        }
    });
    
    $('.gift-product-slider').owlCarousel({
        items: 4,
        center: false,
        nav: false,
        dots: true,
        touchDrag: true,
        loop: false,
        startPosition: 0,
        itemElement: "li",
        stageElement: "ul",
        responsive: {
            0: {
                items: 3,
                loop: false,
                center: false,
                touchDrag: true,
                margin: 10,
                nav: true,
                dots: true,
            },
            600: {
                items: 3,
                loop: false,
                center: false,
                touchDrag: true,
                margin: 20,
                nav: true,
                dots: true,
            },
            960: {
                items: 3,
                loop: false,
                center: false,
                margin: 20,
                nav: true,
                dots: true,
            },
            1400: {
                items: 4,
                loop: false,
                center: false,
                margin: 20,
                nav: true,
                dots: true,
            }
        }
    });

    $('.pdp-product-slider-2').owlCarousel({
        items: 4,
        center: false,
        nav: true,
        dots: true,
        touchDrag: true,
        loop: true,
        startPosition: 0,
        itemElement: "li",
        stageElement: "ul",
        navText: [
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 2,
                loop: true,
                center: false,
                touchDrag: true,
                margin: 10,
                nav: true,
                dots: true,
            },
            600: {
                items: 3,
                loop: true,
                center: false,
                touchDrag: true,
                margin: 20,
                nav: true,
                dots: true,
            },
            960: {
                items: 3,
                loop: true,
                center: false,
                margin: 20,
                nav: true,
                dots: true,
            },
            1400: {
                items: 4,
                loop: true,
                center: false,
                margin: 20,
                nav: true,
                dots: true,
            }
        }
    });

    function updateAriaCurrent(element) {
        element.find('.owl-dot.active').attr('aria-current', 'true');
        element.find('.owl-dot:not(.active)').attr('aria-current', 'false');
    }


    $('.pdp-product-slider-3').owlCarousel({
        items: 5,
        center: false,
        nav: true,
        dots: true,
        touchDrag: true,
        loop: false,
        startPosition: 0,
        itemElement: "li",
        stageElement: "ul",
        navText: [
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                loop: false,
                center: false,
                touchDrag: true,
                stagePadding: 90,
                margin: 10,
                nav: true,
                dots: true,
            },
            600: {
                items: 3,
                loop: false,
                center: false,
                touchDrag: true,
                margin: 20,
                nav: true,
                dots: true,
            },
            960: {
                items: 3,
                loop: false,
                center: false,
                margin: 20,
                nav: true,
                dots: true,
            },
            1400: {
                items: 5,
                loop: false,
                center: false,
                margin: 20,
                nav: true,
                dots: true,
            }
        },
        onInitialized: function () {
            updateAriaCurrent(this.$element);
        },
        onChanged: function () {
            updateAriaCurrent(this.$element);
        },
    });

    $('.pdp-product-slider-covid').owlCarousel({
        items: 4,
        center: true,
        nav: true,
        touchDrag: true,
        loop: true,
        startPosition: 0,
        itemElement: "li",
        stageElement: "ul",
        navText: [
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            600: {
                items: 3,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
            },
            960: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
            },
            1400: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 20,
                nav: true,
                dots: false,
            }
        }
    });

    if ($('.pdp-product-slider').find('.owl-item').not('.cloned').length <= 4) {
        $('.pdp-product-slider').trigger('destroy.owl.carousel');
        $('.pdp-product-slider').owlCarousel({
            loop: false,
            center: false,
            items: 4,
            margin: 20,
            dots: false,
            responsive: {
                0: {
                    items: 1,
                    loop: true,
                    margin: 10,
                    dots: false
                },
                600: {
                    items: 2,
                    loop: true,
                    margin: 20,
                    dots: false
                },
                1000: {
                    items: 4,
                    loop: false,
                    center: false,
                    margin: 20,
                    dots: false
                }
            }
        });
    }

    if ($('.pdp-product-slider-2').find('.owl-item').not('.cloned').length <= 4) {
        $('.pdp-product-slider-2').trigger('destroy.owl.carousel');
        $('.pdp-product-slider-2').owlCarousel({
            loop: false,
            center: false,
            items: 4,
            margin: 20,
            dots: false,
            responsive: {
                0: {
                    items: 2,
                    loop: true,
                    margin: 10,
                    dots: true
                },
                600: {
                    items: 2,
                    loop: true,
                    margin: 20,
                    dots: true
                },
                1000: {
                    items: 4,
                    loop: false,
                    center: false,
                    margin: 20,
                    dots: false
                }
            }
        });
    }
    $('.pdp-product-slider, .pdp-product-slider-2').show();

    if ($('.pdp-product-slider-3').find('.owl-item').not('.cloned').length <= 4) {
        $('.pdp-product-slider-3').trigger('destroy.owl.carousel');
        $('.pdp-product-slider-3').owlCarousel({
            loop: false,
            center: false,
            items: 5,
            margin: 20,
            dots: false,
            responsive: {
                0: {
                    items: 2,
                    loop: true,
                    margin: 10,
                    dots: true
                },
                600: {
                    items: 2,
                    loop: true,
                    margin: 20,
                    dots: true
                },
                1000: {
                    items: 5,
                    loop: false,
                    center: false,
                    margin: 20,
                    dots: false
                }
            }
        });
    }
    $('.pdp-product-slider-3').show();

    $('.pdp-image-slider-container').slick({
        arrows: true,
        dots: false,
        infinite: true,
        nextArrow: '<button class="next-arrow"><span class="icon-CHEVRON"></span></button>',
        prevArrow: '<button class="prev-arrow"><span class="icon-CHEVRON"></span></button>',
        swipeToSlide: true,
        fade: true,
        cssEase: 'linear',
        responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: true,
                swipe: true,
                fade: false,
            }
        },
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                swipe: true,
                fade: false,
            }
        }]
    });

    if (imgNumber <= 4) {
        $('.media-slider-pdp').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            draggable: false,
            fade: true,
            asNavFor: '.navigation-slider-pdp',
            infinite: false,
            responsive: [{
                breakpoint: 768,
                settings: {
                    arrows: true,
                    swipe: true,
                    fade: false,
                    dots: true
                }
            }]
        });
        $('.navigation-slider-pdp .slick-list .slick-track').css({
            'width': '700px !important',
            'display': 'flex',
            'justify-content': 'center'
        });
    } else {
        $('.media-slider-pdp').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            nextArrow: '<a class="pdp-next-arrow" id="next_arrow"><span class="icon-CHEVRON"></span></a>',
            prevArrow: '<a class="pdp-prev-arrow" id="prev_arrow"><span class="icon-CHEVRON"></span></a>',
            fade: true,
            asNavFor: '.navigation-slider-pdp',
            infinite: false,
            responsive: [{
                breakpoint: 768,
                settings: {
                    arrows: true,
                    swipe: true,
                    fade: false,
                    dots: true
                }
            }]
        });
    }

    $('.media-slider-pdp').show();

    if (imgNumber < 3) {
        $('.navigation-slider-pdp .slick-list .slick-track').css('transform', 'none !important;');
    }
    $('.navigation-slider-pdp').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: '<div class="next-arrow-container"><button class="next-arrow-nav"><span class="icon-CHEVRON"></span></button></div>',
        prevArrow: '<div class="prev-arrow-container"><button class="prev-arrow-nav"><span class="icon-CHEVRON"></span></button></div>',
        asNavFor: '.media-slider-pdp',
        dots: false,
        focusOnSelect: true,
        infinite: false
    });

    if (windowSize > 960) {
        $('.navigation-slider-pdp').show();
    }

    $('.prev-arrow-container, .next-arrow-container').on('click', function () {
        if ($('.prev-arrow-container').hasClass('slick-disabled')) {
            $('.prev-arrow-container').hide();
            $('.pdp-prev-arrow').hide();
            $('.next-arrow-container').show();
        } else if ($('.next-arrow-container').hasClass('slick-disabled')) {
            $('.prev-arrow-container').show();
            $('.next-arrow-container').hide();
            $('.pdp-next-arrow').hide();
        } else {
            $('.prev-arrow-container, .next-arrow-container, .pdp-prev-arrow,.pdp-next-arrow').show();
        }
    });

    $('.pdp-next-arrow, .pdp-prev-arrow').on('click', function () {
        if ($('.slick-track').find('.img-magnifier-glass').length > 0) {
            $('.img-magnifier-glass').remove();
        }
        if ($('.pdp-next-arrow').hasClass('slick-disabled')) {
            $('.pdp-prev-arrow').show();
            $('.pdp-next-arrow').hide();
            $('.next-arrow-container').hide();
        } else if ($('.pdp-prev-arrow').hasClass('slick-disabled')) {
            $('.pdp-next-arrow').show();
            $('.pdp-prev-arrow').hide();
            $('.prev-arrow-container').hide();
        } else {
            $('.prev-arrow-container, .next-arrow-container, .pdp-prev-arrow,.pdp-next-arrow').show();
        }
    });

    $('.offers_carousel').slick({
        slidesToShow: 4.5,
        centerMode: true,
        centerPadding: '0px',
        arrows: true,
        swipe: false,
        infinite: true,
        initialSlide: 2,
        nextArrow: '<button type="button" class="next-arrow" role="button" tabindex="0"><span class="icon-CHEVRON"></span></button>',
        prevArrow: '<button type="button" class="prev-arrow" role="button" tabindex="0"><span class="icon-CHEVRON"></span></button>',
        swipeToSlide: true,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                centerMode: true,
                centerPadding: '100px',
                initialSlide: 0,
                arrows: true,
                swipe: true,
                infinite: true,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '87px',
                initialSlide: 1,
                arrows: false,
                swipe: true,
                infinite: true,
            }
        }]
    });

    var imgNumberProduct = $('.wrapper .slick-initialized').find('.product').length;
    if (imgNumberProduct <= 4 && windowSize > 960) {
        $('.wrapper .slick-initialized .slick-list .slick-track').addClass('transform-slick-product');
    }

    var offers_carousel_pdct_nbr = $('.offers_carousel').find('.product').length;
    if (offers_carousel_pdct_nbr <= 4 && windowSize > 960) {
        $('.offers_carousel .slick-list .slick-track').addClass('transform-slick-product');
    }

    var homepage_product_carousel = $('#homepage-product-carousel').find('.product').length;
    if (homepage_product_carousel <= 4 && windowSize > 960) {
        $('#homepage-product-carousel').find('.slick-track').addClass('transform-slick-product');
    }

});

$(document).ready(function () {
    var prevArrowData = $('.prev-arrow-text').attr('data-text');
    $('.prev-arrow').attr('aria-label', prevArrowData);
    var nextArrowData = $('.next-arrow-text').attr('data-text');
    $('.next-arrow').attr('aria-label', nextArrowData);

    $('.iframe_thumbnail_container').on('click', function () {
        $('.iframe_thumbnail_container').css('background-image', 'none');
        $('.thumbnail_video_icon').hide();
        $('iframe').css('opacity', '1');
    });


    //Remove attribut role=presentation
    $(".media-slider-pdp li").each(function () {
        $(this).removeAttr('role');
    });

});

document.addEventListener("fullscreenchange", function () {
    $('.navigation-slider-pdp').slick('refresh');
});

document.addEventListener("mozfullscreenchange", function () {
    $('.navigation-slider-pdp').slick('refresh');
});

document.addEventListener("webkitfullscreenchange", function () {
    $('.navigation-slider-pdp').slick('refresh');
});

document.addEventListener("msfullscreenchange", function () {
    $('.navigation-slider-pdp').slick('refresh');
});

$('.media-slider-pdp').on('swipe', function () {
    $('.iframe_thumbnail_container').hasClass('slick-active') ? $('.product_stamp_icon').hide() : $('.product_stamp_icon').show();
});

$(document).ready(function () {
    $('.owl-carousel1').owlCarousel({
        loop: false,
        center: false,
        margin: 40,
        nav: true,
        dots: false,
        stagePadding: 110,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        items: 4,
        navText: [
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            600: {
                items: 2,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            960: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
            },
            1500: {
                items: 4,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 20,
                nav: true,
                dots: false,
            }
        }
    });

    if ($('.owl-carousel1').find('.owl-item').not('.cloned').length <= 4) {
        $('.owl-carousel1').trigger('destroy.owl.carousel');
        $('.owl-carousel1').owlCarousel({
            loop: false,
            center: false,
            items: 4,
            margin: 20,
            responsive: {
                0: {
                    items: 1,
                    loop: true
                },
                600: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1000: {
                    items: 4,
                    loop: false,
                    nav: true,
                    center: false,
                }
            }
        });
    }
});

$(document).ready(function () {

    var owlaquavideo = $('.owlaquavideo');

    owlaquavideo.owlCarousel({
        loop: true,
        center: false,
        nav: true,
        dots: false,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        items: 1,
        navText: [
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                center: true,
                touchDrag: true,
                margin: 40,
                nav: true,
                dots: false,
            },
            600: {
                items: 1,
                center: true,
                touchDrag: true,
                margin: 40,
                nav: true,
                dots: false,
            }
        }
    });

    var owlred = $('.landing-page-carousel-artists');

    owlred.owlCarousel({
        loop: false,
        center: false,
        nav: true,
        dots: true,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        items: 1,
        navText: [
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                center: true,
                touchDrag: true,
                nav: true,
                dots: true,
            },
            600: {
                items: 1,
                center: true,
                touchDrag: true,
                nav: true,
                dots: true,
            },
            960: {
                items: 1,
                center: false,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: true,
                loop: false,
            },
            1500: {
                items: 1,
                center: false,
                stagePadding: 110,
                margin: 20,
                nav: true,
                dots: true,
            }
        }
    });

    var owlred2 = $('.landing-page-carousel-artists-rouge');

    owlred2.owlCarousel({
        loop: false,
        center: false,
        nav: true,
        dots: false,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        navText: [
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                center: true,
                touchDrag: true,
                nav: true,
                dots: false,
            },
            600: {
                items: 1,
                center: true,
                touchDrag: true,
                nav: true,
                dots: false,
            },
            960: {
                items: 3,
                center: false,
                slideBy: 3,
                nav: true,
                dots: false,
                loop: false,
            },
            1500: {
                items: 3,
                slideBy: 3,
                center: false,
                nav: true,
                dots: true,
            }
        }
    });

    var windowSize = $(window).width();
    if (windowSize < 960) {
        $('.landing-rouge-slider-video-img-container').owlCarousel({
            center: false,
            nav: true,
            dots: false,
            itemElement: "li",
            stageElement: "ul",
            loop: false,
            rewind: false,
            stagePadding: 10,
            items: 5,
            navText: [
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
            ]
        });
        $('.landing-rouge-slider-video-img-container .owl-prev').click(function () {
            $('.landing-page-carousel-artists-rouge .owl-prev').trigger('click');
        });
        $('.landing-rouge-slider-video-img-container .owl-next').click(function () {
            $('.landing-page-carousel-artists-rouge .owl-next').trigger('click');
        });
    }

    $("#rouge-artist-slider .owl-prev, #rouge-artist-slider .owl-next").on("click", function () {
        var iframes = $('.owl-item iframe');

        iframes.each(function () {
            var player = new Vimeo.Player($(this)); // eslint-disable-line no-undef
            player.pause();
        });
    });

    $(".landing-rouge-slider-video-img-button").on("click", function (e) {
        $(".landing-rouge-slider-video-img-button").removeClass('active');
        $(this).addClass('active');
        var windowSize = $(window).width();
        e.preventDefault();
        var number = $(this).attr('data-video');
        var group = $(this).attr('data-group');
        var videonumber = ' #artistvideo' + number;

        if (windowSize > 500) {
            owlred2.trigger('to.owl.carousel', [group - 1]);
        }else{
            owlred2.trigger('to.owl.carousel', [number - 1]);
        }
        var player = new Vimeo.Player($(videonumber)); // eslint-disable-line no-undef


        setTimeout(function () {
            player.play();
        }, 200);
    });


    if ($('.landing-page-carousel-artists').find('.owl-item').not('.cloned').length <= 1) {
        $('.landing-page-carousel-artists').trigger('destroy.owl.carousel');
        $('.landing-page-carousel-artists').owlCarousel({
            loop: false,
            center: false,
            items: 4,
            margin: 20,
            responsive: {
                0: {
                    items: 1,
                    loop: true
                },
                600: {
                    items: 1,
                    loop: true,
                    center: false,

                },
                1000: {
                    items: 1,
                    loop: false,
                    center: false,
                }
            }
        });
    }
    var $owltryon = $('.tryon-mobile');

    $owltryon.each( function() {
        $(this).children().each( function( index ) {
            $(this).attr( 'data-position', index );
        });
    });

    if ($('.shadefinderWrapper-Revamp').length) {
        $owltryon.each(function () {
           var self = $(this);
           var loopsParam = false;
           var centerMode = false;
           var maxDisplayShadeSquare = PropertiesPreferences.MAXIMUM_SHADE_SQUARE;
           
            if (self.find('.shadefinder-shadesquare').length > maxDisplayShadeSquare) {
                loopsParam = true;
                centerMode = true;
            }

            self.owlCarousel({
                loop: loopsParam,
                rewind: false,
                center: centerMode,
                margin: 10,
                nav: false,
                dots: false,
                transitionStyle: "fade",
                itemElement: "li",
                stageElement: "ul",
                autoWidth: true,
                onDragged: onDraggedFunction,
                items: 5,
                navText: [
                    '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                    '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
                ]
            });
           
        });
        
    } else {
        $owltryon.owlCarousel({
            loop: false,
            rewind: false,
            center: true,
            nav: true,
            dots: false,
            transitionStyle: "fade",
            itemElement: "li",
            stageElement: "ul",
            autoWidth: true,
            onDragged: onDraggedFunction,
            items: 5,
            navText: [
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
            ]
        });
    }
    $(document).on('click', '.owl-item>svg', function() {
        $owltryon.trigger('to.owl.carousel', $(this).data( 'position' ) ); 
    });

    function onDraggedFunction(event) {
        var childrens = $(event.target).find('.shadefinder-shadesquare');
        var selectedValue = event.item.index;
        childrens.each( function(){
            if(parseInt($(this).attr('data-position')) === selectedValue){
                $(this).trigger('click');
            }
        })
    }

    $(document).on('click', '.owl-prev, .owl-next', function() {
        var actualposition = $(this).closest('.owl-carousel').find('.owl-item.focused .shadefinder-shadesquare').attr('data-position');
        $(this).closest('.owl-carousel').find('.shadefinder-shadesquare').each(function(){
            if(parseInt($(this).attr('data-position')) === parseInt(actualposition)){
                $(this).trigger('click');
            }
        });
    });

    $('.landing-page-carousel-clients').owlCarousel({
        loop: false,
        center: false,
        margin: 40,
        nav: true,
        dots: false,
        stagePadding: 110,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        items: 4,
        navText: [
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1.2,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            600: {
                items: 1.2,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            960: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
            },
            1500: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 20,
                nav: true,
                dots: false,
            }
        }
    });

    if ($('.landing-page-carousel-clients').find('.owl-item').not('.cloned').length <= 3) {
        $('.landing-page-carousel-clients').trigger('destroy.owl.carousel');
        $('.landing-page-carousel-clients').owlCarousel({
            loop: false,
            center: false,
            items: 3,
            margin: 20,
            nav: true,
            navText: [
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
            ],
            responsive: {
                0: {
                    items: 1.2,
                    loop: true,
                    nav: true,
                    dots: false,


                },
                600: {
                    items: 1.2,
                    loop: true,
                    nav: true,
                    dots: false,

                },
                1000: {
                    items: 3,
                    loop: false,
                    center: false,
                    nav: false,
                    dots: false,

                }
            }
        });
    }
    $('.landing-carousel-rouge-artist-discover-tips').owlCarousel({
        loop: false,
        center: false,
        margin: 40,
        nav: true,
        dots: false,
        stagePadding: 110,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        items: 4,
        navText: [
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            600: {
                items: 1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            960: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
            },
            1500: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 20,
                nav: true,
                dots: false,
            }
        }
    });

    if ($('.landing-carousel-rouge-artist-discover-tips').find('.owl-item').not('.cloned').length <= 3) {
        $('.landing-carousel-rouge-artist-discover-tips').trigger('destroy.owl.carousel');
        $('.landing-carousel-rouge-artist-discover-tips').owlCarousel({
            loop: false,
            center: false,
            items: 3,
            nav: true,
            navText: [
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
            ],
            responsive: {
                0: {
                    items: 1,
                    loop: true,
                    nav: true,
                    dots: false,


                },
                600: {
                    items: 1,
                    loop: true,
                    nav: true,
                    dots: false,

                },
                1000: {
                    items: 3,
                    loop: false,
                    center: false,
                    nav: false,
                    dots: false,

                }
            }
        });
    }

    var owlreplay = $('.landing-page-bambuser-carousel');

    owlreplay.owlCarousel({
        loop: false,
        center: false,
        nav: true,
        dots: false,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        items: 3,
        navText: [
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                center: true,
                touchDrag: true,
                nav: true,
                dots: false,
                margin: 20
            },
            600: {
                items: 1,
                center: true,
                touchDrag: true,
                nav: true,
                dots: false,
                margin: 20
            },
            960: {
                items: 1,
                center: true,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
                loop: false,
            },
            1200: {
                items: 3,
                center: false,
                margin: 20,
                nav: true,
                dots: false,
            }
        }
    });

    $(".foundation-finder-page").slick({
        slidesToShow: 1,
        centerPadding: '0',
        arrows: false,
        swipe: false,
        infinite: false,
        vertical: true,
        verticalSwiping: false,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                centerPadding: '0',
                initialSlide: 0,
                arrows: false,
                infinite: false,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerPadding: '0',
                initialSlide: 0,
                arrows: false,
                infinite: false,
            }
        }]
    });

    $(".morpho-genius-page").slick({
        slidesToShow: 1,
        centerPadding: '0',
        initialSlide: 0,
        arrows: true,
        swipe: false,
        infinite: false,
        vertical: true,
        verticalSwiping: true,          
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                centerPadding: '0',
                initialSlide: 0,
                arrows: false,
                infinite: false,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerPadding: '0',
                initialSlide: 0,
                arrows: false,
                infinite: false,
            }
        }]
    });

    $(".primer-finder-page").slick({
        slidesToShow: 1,
        centerPadding: '0',
        arrows: false,
        swipe: false,
        infinite: false,
        vertical: true,
        verticalSwiping: false,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                centerPadding: '0',
                initialSlide: 0,
                arrows: false,
                infinite: false,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerPadding: '0',
                initialSlide: 0,
                arrows: false,
                infinite: false,
            }
        }]
    });

    $('.landing-page-carousel-models-shots').owlCarousel({
        loop: false,
        center: false,
        margin: 40,
        nav: true,
        dots: false,
        stagePadding: 110,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        items: 4,
        navText: [
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            600: {
                items: 1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: false,
            },
            960: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 40,
                nav: true,
                dots: false,
            },
            1500: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 110,
                margin: 20,
                nav: true,
                dots: false,
            }
        }
    });

    if ($('.landing-page-carousel-models-shots').find('.owl-item').not('.cloned').length <= 3) {
        $('.landing-page-carousel-models-shots').trigger('destroy.owl.carousel');
        $('.landing-page-carousel-models-shots').owlCarousel({
            loop: false,
            center: false,
            items: 3,
            nav: true,
            navText: [
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
            ],
            responsive: {
                0: {
                    items: 1,
                    loop: true,
                    nav: true,
                    dots: false,


                },
                600: {
                    items: 1,
                    loop: true,
                    nav: true,
                    dots: false,

                },
                1000: {
                    items: 3,
                    loop: false,
                    center: false,
                    nav: false,
                    dots: false,

                }
            }
        });
    }
    $('.landing-page-carousel-results').owlCarousel({
        loop: false,
        center: false,
        margin: 40,
        nav: true,
        dots: true,
        stagePadding: 110,
        transitionStyle: "fade",
        itemElement: "li",
        stageElement: "ul",
        items: 4,
        navText: [
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-chevron-thin-right" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                items: 1.1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 30,
                margin: 10,
                nav: true,
                dots: true,
            },
            600: {
                items: 1.1,
                loop: true,
                center: true,
                touchDrag: true,
                stagePadding: 90,
                margin: 40,
                nav: true,
                dots: true,
            },
            960: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 90,
                margin: 40,
                nav: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true,
            },
            1500: {
                items: 3,
                loop: true,
                center: false,
                stagePadding: 90,
                nav: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true,
            }
        }
    });

    if ($('.landing-page-carousel-results').find('.owl-item').not('.cloned').length <= 3) {
        $('.landing-page-carousel-results').trigger('destroy.owl.carousel');
        $('.landing-page-carousel-results').owlCarousel({
            loop: false,
            center: false,
            items: 3,
            margin: 20,
            responsive: {
                0: {
                    items: 1.2,
                    loop: true
                },
                600: {
                    items: 1.2,
                    loop: true
                },
                1000: {
                    items: 3,
                    loop: false,
                    center: false,
                }
            }
        });
    }
});

$(document).ready(function () {
    var sync1 = $("#sync1");
    var sync2 = $("#sync2");
    var slidesPerPage = 4; //globaly define number of elements per page
    var syncedSecondary = true;

    sync1.on('initialized.owl.carousel', function () {
        sync1.find(".owl-item").eq(0).addClass("current");
        
        sync1.find(".item").each( function(){
            //console.log('data-order', $(this).attr('data-order'));
            var order =  parseInt($(this).attr('data-order'));
            //console.log('data-order', order);
            //$(this).closest('.owl-item').css('order', order);
            $(this).closest('.owl-item').attr("data-order", order);
        });
        $(".owl-stage").css('display', 'flex');     
    }).owlCarousel({
        items: 1,
        slideSpeed: 2000,
        nav: true,
        autoplay: false,
        autoHeight: true,
        autoHeightClass: 'owl-height',
        margin: 2,
        dots: false,
        loop: false,
        center: false,
        itemElement: "li",
        stageElement: "ul",
        responsiveRefreshRate: 200,
        navText: [
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
    }).on('changed.owl.carousel', onChange);

    sync2.on('initialized.owl.carousel', function () {
        sync2.find(".owl-item").eq(0).addClass("current").attr('aria-current', true);
        
        sync2.find(".items").each( function(){
            //console.log('data-order', $(this).attr('data-order'));
            var order =  parseInt($(this).attr('data-order'));
            //console.log('data-order', order);
            //$(this).closest('.owl-item').css('order', order);
            $(this).closest('.owl-item').attr("data-order", order);
        })

        $(".owl-stage").css('display', 'flex');
        
    }).owlCarousel({
        items: slidesPerPage,
        dots: false,
        nav: true,
        margin: 10,
        stagePadding: 10,
        itemElement: "li",
        stageElement: "ul",
        slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
        responsiveRefreshRate: 100,
        navText: [
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
            '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
        ],
        responsive: {
            0: {
                nav: false
            },
            600: {
                nav: false
            },
            1000: {
                nav: true
            }
        }
    }).on('changed.owl.carousel', syncPosition2);

    setTimeout(() => {
        // place video between images
        var video1sync1 = $('.ytplayerPDP1').closest('li');
        var video1sync2 = $('#video1thumb').closest('li');
        var video1DataOrder = parseInt($('#video1thumb').closest('.owl-item').attr("data-order"));
        var video2sync1 = $('.ytplayerPDP2').closest('li');
        var video2sync2 = $('#video2thumb').closest('li');
        var video2DataOrder = parseInt($('#video2thumb').closest('.owl-item').attr("data-order"));
        var video3sync1 = $('.ytplayerPDP3').closest('li');
        var video3sync2 = $('#video3thumb').closest('li');
        var video3DataOrder = parseInt($('#video3thumb').closest('.owl-item').attr("data-order"));
        var video4sync1 = $('.ytplayerPDP4').closest('li');
        var video4sync2 = $('#video4thumb').closest('li');
        var video4DataOrder = parseInt($('#video4thumb').closest('.owl-item').attr("data-order"));
        var video5sync1 = $('.ytplayerPDP5').closest('li');
        var video5sync2 = $('#video5thumb').closest('li');
        var video5DataOrder = parseInt($('#video5thumb').closest('.owl-item').attr("data-order"));

        $("#sync1").find("li").each(function() { 
            var DataOrderLI = parseInt($(this).attr('data-order'));
            if ( video1DataOrder - DataOrderLI == 5) {
                $(this).after(video1sync1);
            }
            if ( video2DataOrder - DataOrderLI == 5) {
                $(this).after(video2sync1);
            }
            if ( video3DataOrder - DataOrderLI == 5) {
                $(this).after(video3sync1);
            }
            if ( video4DataOrder - DataOrderLI == 5) {
                $(this).after(video4sync1);
            }
            if ( video5DataOrder - DataOrderLI == 5) {
                $(this).after(video5sync1);
            }
        });

        $("#sync2").find("li").each(function(index) {
            var DataOrderLI = parseInt($(this).attr('data-order'));
            if ( video1DataOrder - DataOrderLI == 5) {
                $(this).after(video1sync2);
            }
            if ( video2DataOrder - DataOrderLI == 5) {
                $(this).after(video2sync2);
            }
            if ( video3DataOrder - DataOrderLI == 5) {
                $(this).after(video3sync2);
            }
            if ( video4DataOrder - DataOrderLI == 5) {
                $(this).after(video4sync2);
            }
            if ( video5DataOrder - DataOrderLI == 5) {
                $(this).after(video5sync2);
            }

            // add x of x to the thumbnail slider on PDP
            var listItemIndex = index + 1;
            var listItemTotal = $('#sync2 li').length;
            var listItemText = listItemIndex + ' of ' + listItemTotal;
            $(this).append('<span class="sr-only">' + listItemText + '</span>');
        })
        // place video between images END
    }, 2000);

    function onChange(el) {
        syncPosition(el);

        var disabledElements = $('.owl-prev.disabled, .owl-next.disabled');
        var enabledElements = $(
            '.owl-prev:not(.disabled), .owl-next:not(.disabled)'
        );

        disabledElements.attr('tabindex', -1);
        enabledElements.attr('tabindex', 0);
    }

    function syncPosition(el) {
        //if you set loop to false, you have to restore this next line
        var current = el.item.index;
        //end block

        if (current > 0){
            $('.product-promo').show();
        } else {
            $('.product-promo').hide();
        }

        sync2
            .find(".owl-item")
            .removeClass("current").attr('aria-current', false)
            .eq(current)
            .addClass("current").attr('aria-current', true);
        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        if (current > end) {
            sync2.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
            sync2.data('owl.carousel').to(current - onscreen, 100, true);
        }
    }

    function syncPosition2(el) {
        if (syncedSecondary) {
            var number = el.item.index;
            sync1.data('owl.carousel').to(number, 100, true);
        }
    }

    sync2.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });
    $("#sync2").find(".owl-item.active").each(function() {
        $(this).attr({'tabindex' : 0});
    });
    $(".owl-item").on('keydown',function(e){
        var key  = e.keyCode;
        if(key == 13 || key == 32){
            if ($(this).hasClass('active')) {
                $(this).trigger('click');
            }
        }
    });

    $('.owl-prev').each(function () {
        var previousPanel = $('.previous-arrow-carousel').text();
        $(this).attr({'tabindex' : 0, 'role' : 'button'});
        $(this).find('.sr-only').replaceWith("<span class='sr-only'>" + previousPanel + "</span>");

        if ($(this).hasClass('disabled')) {
            $(this).attr('tabindex', '-1');
        }
    });

    $('.owl-next').each(function () {
        var nextPanel = $('.next-arrow-carousel').text();
        $(this).attr({'tabindex' : 0, 'role' : 'button'});
        $(this).find('.sr-only').replaceWith("<span class='sr-only'>" + nextPanel + "</span>");
    });

    var owl = $('.owl-carousel');
    // Listen to owl events:
    owl.on('drag.owl.carousel', function () {
        $('body').trigger('LoadSliderImages');
    })

    $('.owl-next, .owl-prev').click(function () {
        $('body').trigger('LoadSliderImages');
        if ($(this).hasClass('owl-prev') && $(this).hasClass('disabled')) {
            $('.overlay-left').addClass('overlay-right').removeClass('overlay-left');
        }
        if ($(this).hasClass('owl-next') && $(this).hasClass('disabled')) {
            $('.overlay-right').addClass('overlay-left').removeClass('overlay-right');
        }
    });
});