'use strict';

const { trapFocus } = require('./util');

$(document).ready(function () {
    $(document).on("click.countrySelectorHandler", '.accessible-select-country-selector' ,function() {
        var self = $(this);

        self.addClass('shown').siblings('.shown').removeClass('shown');
        self.parents('.accessible_select').addClass('active');
        self.parents('.modal-content').addClass('active');
    });

    $(document).on("click.backCountrySelector",".modal-content .dropdown-menu-back-container",function() {
        var self = $(this);
        self.parents('.modal-content').removeClass('active');
        self.parents('.modal-content').find('.accessible_select').removeClass('active');
        self.parents('.modal-content').find('.accessible-select-country-selector').removeClass('shown').siblings('.shown').removeClass('shown');
    });

    var calculateOffsetTop = $('.footer-country-selector-container'); 

    calculateOffsetTop.find('.country_selector_white').on('click', function () {
        window.mobilePopinOpen = true;
        var scrollTop = $(window).scrollTop();
        $('#countrySelectorpop').addClass('is-open');
        $('body').attr('data-scrollTop', scrollTop);
        $('header').addClass('is-hovred');
    });

    var focusTriggerElement = null;

    $('.country_selector_white').on('click', function() {
        trapFocus($('#countrySelectorpop .modal-content')[0]);
        setTimeout(() => {
            $('.modal-content .close').focus();
        }, 200);
        focusTriggerElement = this;
    })
    
    $(document).on("click.closeCountrySelector","#countrySelectorpop .modal-content .close",function() {
        var $body = $('body');
        var self = $(this);
        window.mobilePopinOpen = false;
        self.parents('.micromodal-slide').removeClass('is-open');
        $('header').removeClass('is-hovred');
        focusTriggerElement.focus();
        window.scrollTo({ top: $body.attr('data-scrollTop'), left: 0, behavior: 'instant' });
    });

});