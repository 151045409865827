'use strict';

var scrollAnimate = require('base/components/scrollAnimate');
var util = require('../components/util');

function handleFooterMenuCategory() {
    $('.footer-content-right .footer-menu-category').each(function () {
        var element;
        if (util.isTablet()) {
            element = $(this).html();
            $(this).replaceWith(
                '<button class="footer-menu-category title" aria-expanded="false">' +
                    element +
                    '</button>'
            );
        } else {
            element = $(this).html();
            $(this).replaceWith(
                '<div role="heading" aria-level="4" class="footer-menu-category">' +
                    element +
                    '</div>'
            );
        }
    });
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    // Initial execution
    handleFooterMenuCategory();

    // Resize event handler
    $(window).on('resize', function () {
        handleFooterMenuCategory();
    });
    

    $('.home-email-signup .newsletter-submit').click(function (e) {
        e.preventDefault();
        // var $error = $(this).closest('.input-group').find('.invalid-feedback');
        // var $success = $(this).closest('.home-email-signup').find('.success-message');

        var $error = $('.home-email-signup .invalid-feedback');
        var $errorValidateRGPD = $('#checkbox-hpEmailSignUp-error');
        var $success = $('.home-email-signup .success-message');
        
        $error.hide();
        $success.hide();
        
        // var $form = $(this);

        var $form = $('.home-email-signup .newsletter-signup');

        var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/; 


        //var email = $(this).closest('.input-group').find('form-control');
        var email = $('#hpEmailSignUp');

        if (email.val().trim() !== '') {
            if (!filter.test(email.val())) {
                $error.show();
                $error.addClass('d-block');
                $error.attr("role", "alert");
            } else {
                if($('#checkbok-hpEmailSignUp').length === 0 || $('#checkbok-hpEmailSignUp').val() === "1") {
                    $errorValidateRGPD.hide();
                    $errorValidateRGPD.attr('role', '');
                    $(".newsletter-signup .newsletter-submit").attr("disabled", true).attr('aria-disabled', true);
                
                    $.ajax({
                        url: $form.data('action'),
                        method: 'POST',
                        data: $form.serialize(),
                        success: function () {
                            $('.newsletter-signup').addClass('d-none');
                            $success.show();
                            $success.addClass('isOptin');
                            
                            dataLayer.push({
                                'newsletter_type': 'Mail',
                                'event':'newsletter_subscription'
                            });
                        },
                        error: function () {

                        }
                    });
                } else {
                    $errorValidateRGPD.show();
                    $errorValidateRGPD.attr('role', 'alert');
                }
            }
        } else if (email.val().trim() === '') {
            $error.show();
        }
    });

    $(document).on('click', '#js-btn-thanks', function () {
        $("#js-newsletter-container").hide();
        localStorage.setItem('optinpopin', 'cookieNewsletter');
    });

    $(".popin-email-signup .new-email-input").on("keyup change", function() {
        var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/; 

        $('.popin-email-signup #hpEmailSignUp-error-empty').hide();
        $('.popin-email-signup #hpEmailSignUp-error').hide();

        if (filter.test($(this).val()) || $(this).val().trim() === '') {
            $('.popin-email-signup #hpEmailSignUp-error').removeClass('d-block').addClass('d-none');
        }else {
            $('.popin-email-signup #hpEmailSignUp-error').addClass('d-block').removeClass('d-none');
        }
    })
    $(".popin-email-signup .new-phone-input").on("keyup change", function() {
        var filterPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

        $('.popin-email-signup #hpPhoneSignUp-error-empty').hide();
        $('.popin-email-signup #hpPhoneSignUp-error').hide();

        if (filterPhone.test($(this).val())) {
            $('.popin-email-signup #hpPhoneSignUp-error').removeClass('d-block').addClass('d-none');
            $('.popin-email-signup #hpPhoneSignUp-error-empty').removeClass('d-block').addClass('d-none');
        } else {
            if ($(this).val().trim() !== ''){
                $('.popin-email-signup #hpPhoneSignUp-error').addClass('d-block').removeClass('d-none');
            } else {
                $('.popin-email-signup #hpPhoneSignUp-error').removeClass('d-block').addClass('d-none');
            }
        }
    });

    $("#checkbok-hpEmailSignUp-popin, #label-checkbok-hpEmailSignUp-popin").click(function() {
        if($(this).is(":checked")) {
            $('#checkbox-hpEmailSignUp-popin-error').hide();
        }
    });

    $('#popinEmailSignUp').on('input', function() {
        if($(this).val().length > 0){
            $('.newsletterpopin-submit').attr('aria-disabled', false);
        } else {
            $('.newsletterpopin-submit').attr('aria-disabled', true);
        }
    });

    $('.btn-thanks').hide();
    
    if ($('.newsletter-us-content').length) {
        var $error_popin = $('.popin-email-signup .invalid-feedback');
        var $emailSignUpError = $('.popin-email-signup #hpEmailSignUp-error-empty');
        var $errorValidateRGPD_popin = $('#checkbox-hpEmailSignUp-popin-error');
        
        $error_popin.hide();
        $emailSignUpError.hide();

        var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/; 


        var email = $('#popinEmailSignUp');

        $('.btn-before-submit').hide();
        $("#popinEmailSignUp").on('keyup keypress', function(event) {
            if (event.keyCode === 13) {
                if (email.val().trim() !== '') {
                    if (!filter.test(email.val())) {
                        $error_popin.show();
                        $error_popin.addClass('d-block');
                    } else {
                        $errorValidateRGPD_popin.hide();
                        $('.popin-email-signup #hpEmailSignUp-error').removeClass('d-block').addClass('d-none');
                        $('.popin-email-signup #hpPhoneSignUp-error').removeClass('d-block').addClass('d-none');
                        var self = $(this);
                        var parent = self.parents('.newsletter-us-content');
                        var customInputEmail = parent.find('.custom-input-email');
                        var customInputPhone = parent.find('.custom-input-phone');
                        var btnChange = parent.find('.btn-change-before-submit');
                        var btnSubmit = parent.find('.btn-before-submit');
                
                        customInputEmail.addClass('d-none');
                        customInputPhone.removeClass('d-none');
                        btnChange.addClass('hidden');
                        btnChange.hide();
                        btnSubmit.removeClass('hidden');
                        btnSubmit.show();
                    }
                } else if (email.val().trim() === '') {
                    $errorValidateRGPD_popin.show();
                    $error_popin.show();
                    $emailSignUpError.show();
                }
            }
        });

    }

    $('.newsletter-us-content').find('.btn-change-before-submit button').on('click', function () {
        var $error_popin = $('.popin-email-signup .invalid-feedback');
        var $emailSignUpError = $('.popin-email-signup #hpEmailSignUp-error-empty');
        var $errorValidateRGPD_popin = $('#checkbox-hpEmailSignUp-popin-error');
        
        $error_popin.hide();
        $emailSignUpError.hide();

        var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/; 


        var email = $('#popinEmailSignUp');

        if (email.val().trim() !== '') {
            if (!filter.test(email.val())) {
                $error_popin.show();
                $error_popin.addClass('d-block');
            } else {
                if($('#checkbok-hpEmailSignUp-popin').length === 0 || $('#checkbok-hpEmailSignUp-popin').val() === "1") {
                    $errorValidateRGPD_popin.hide();
                    $('.popin-email-signup #hpEmailSignUp-error').removeClass('d-block').addClass('d-none');
                    $('.popin-email-signup #hpPhoneSignUp-error').removeClass('d-block').addClass('d-none');
                    var self = $(this);
                    var parent = self.parents('.newsletter-us-content');
                    var customInputEmail = parent.find('.custom-input-email');
                    var customInputPhone = parent.find('.custom-input-phone');
                    var btnChange = self.parents('.btn-change-before-submit');
                    var btnSubmit = parent.find('.btn-before-submit');
            
                    customInputEmail.addClass('d-none');
                    customInputPhone.removeClass('d-none');
                    btnChange.addClass('hidden');
                    btnSubmit.removeClass('hidden');
                    btnSubmit.show();
                    
                } else {
                    $errorValidateRGPD_popin.show();
                }
            }
        } else if (email.val().trim() === '') {
            $errorValidateRGPD_popin.show();
            $error_popin.show();
            $emailSignUpError.show();
        }

    });

    function successNewsletterSubscribe($form, $errorValidateRGPD_popin , $before_success, $after_success, $btn_shop, $btn_submit) {
        $errorValidateRGPD_popin.hide();
        $('.popin-email-signup #hpEmailSignUp-error').removeClass('d-block').addClass('d-none');
        $(".newsletterpopin-signup .newsletterpopin-submit").attr("disabled", true).attr('aria-disabled', true);
        
        $.ajax({
            url: $form.data('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function () {
                $('.newsletterpopin-signup').addClass('d-none');
                $before_success.addClass('hidden');
                $after_success.removeClass('hidden');
                $btn_shop.removeClass('hidden');
                $btn_shop.show();
                $btn_submit.addClass('hidden');
                $after_success.addClass('isOptin');
                localStorage.setItem('optinpopin', 'cookieNewsletter');
         
                dataLayer.push({
                    'newsletter_type': 'Mail',
                    'event':'newsletter_subscription'
                });
            },
            error: function () {
            }
        });
    }

    $('.popin-email-signup .newsletterpopin-submit').click(function (e) {
        e.preventDefault();

        var $error_popin = $('.popin-email-signup .invalid-feedback');
        var $emailSignUpError = $('.popin-email-signup #hpEmailSignUp-error-empty');
        var $phoneSignUpError = $('.popin-email-signup #hpPhoneSignUp-error-empty');
        var $errorValidateRGPD_popin = $('#checkbox-hpEmailSignUp-popin-error');
        var $before_success = $('.popin-email-signup .newsletter-before-submit');
        var $after_success = $('.popin-email-signup .newsletter-after-submit');
        var $btn_shop = $(".btn-thanks");
        var $btn_submit = $(".btn-before-submit");
        var $popinCaSucces = $('#popin-ca-succes');
        
        $error_popin.hide();
        $after_success.addClass('hidden');
        $emailSignUpError.hide();
        $phoneSignUpError.hide();

        var $form = $('.popin-email-signup .newsletterpopin-signup');

        var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/; 
        var filterPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

        var email = $('#popinEmailSignUp');
        var phone = $('#popinPhoneSignUp');

        if (email.val().trim() !== '') {
            if (!filter.test(email.val())) {
                $error_popin.show();
                $error_popin.addClass('d-block');
            } else {
                if($('#checkbok-hpEmailSignUp-popin').length === 0 || $('#checkbok-hpEmailSignUp-popin').val() === "1") {
                    if(phone.length) {
                        if (phone.val().trim() !== '') {
                            if (!filterPhone.test(phone.val())) {
                                $error_popin.show();
                                $error_popin.addClass('d-block');
                            } else {
                                if ($($popinCaSucces.length)) {
                                    $popinCaSucces.parents('.newsletter-us-content').addClass('popin-succes');
                                }
                                successNewsletterSubscribe($form, $errorValidateRGPD_popin , $before_success, $after_success, $btn_shop, $btn_submit);
                            }
                        } else if (phone.val().trim() === '') {
                            $errorValidateRGPD_popin.show();
                            $error_popin.show();
                            $phoneSignUpError.show();
                        }
                    }else {
                        if ($($popinCaSucces.length)) {
                            $popinCaSucces.parents('.newsletter-us-content').addClass('popin-succes');
                        }
                        successNewsletterSubscribe($form, $errorValidateRGPD_popin , $before_success, $after_success, $btn_shop, $btn_submit);
                    }
                } else {
                    $btn_shop.addClass('hidden');
                    $btn_shop.hide();
                    $btn_submit.removeClass('hidden');
                    $errorValidateRGPD_popin.show();
                }
            }
        } else if (email.val().trim() === '') {
            $btn_shop.addClass('hidden');
            $btn_shop.hide();
            $btn_submit.removeClass('hidden');
            if ($('#checkbok-hpEmailSignUp-popin').val() === "0"){
                $errorValidateRGPD_popin.show();
            }
            $error_popin.show();
            $emailSignUpError.show();
        }

    });

    $(".rgpd-footer-more").click( function () {
        $(this).hide();
        $('.rgpd-footer-complete').show();
    });
    $(".rgpd-footer-less").click( function () {
        $('.rgpd-footer-complete').hide();
        $('.rgpd-footer-more').show();
    });

    $('#label-checkbok-hpEmailSignUp').click( function() {
        if($('#checkbok-hpEmailSignUp').val() === "1") {
            $('#checkbok-hpEmailSignUp').val(0);
        } else {
            $('#checkbok-hpEmailSignUp').val(1);
        }
    });

    $('#label-checkbok-hpEmailSignUp-popin').click( function() {
        if($('#checkbok-hpEmailSignUp-popin').val() === "1") {
            $('#checkbok-hpEmailSignUp-popin').val(0);
        } else {
            $('#checkbok-hpEmailSignUp-popin').val(1);
        }
    });

    if($('.fullBannerLink').length) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
            $('.fullBannerLink img').addClass('iphone-top-banner')
        } else {
            $('.fullBannerLink img').removeClass('iphone-top-banner')
        }
    }

    if ($('.iframe-container-mobile')) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
            $('.iframe-container-mobile .iframeplayer').addClass('iphone-vimeo-banner')
        } else {
            $('.iframe-container-mobile .iframeplayer').removeClass('iphone-vimeo-banner')
        }
    }

    if(util.isMobile()){
        var outerHeightWindow = $(window).outerHeight();
        $('.fullBannerLink img').each(function() {
            $(this).css({'height' :outerHeightWindow + 'px' });
        });
    }
};
