module.exports = {
    triggerProductClick: function () {
        $('body').on('click', '.product-tile', function (event) {
            try { 
                var gtmInfo = '';
                if ($(event.target).closest('.product-tile').length) {
                    gtmInfo = $(event.target).closest('.product-tile').attr('data-gtm-info');
                    if (JSON.parse(gtmInfo).ecommerce.click) {
                        var currentCategory = JSON.parse(gtmInfo).ecommerce.click.actionField.list;
                        localStorage.setItem("currentCategory", currentCategory);
                    }
                }
                
                if (gtmInfo != '' && gtmInfo != 'null') {
                    dataLayer.push(JSON.parse(gtmInfo));
                }
            } catch (e) {
                // TODO: handle exception
                console.warn('Unable to trigger product tile gtm click event  ' + e.message);
            }
        });
    },
    
    triggerSearchSuggestionLinks: function () {
        $('body').on('click', '.product-suggestion-link', function () {
            try {
                var gtmInfo = JSON.parse($(this).attr('data-gtm-info'));
                var currentCategory = gtmInfo.ecommerce.click.actionField.list;
                localStorage.setItem("currentCategory", currentCategory);

                if (gtmInfo != '' && gtmInfo != 'null') {
                    dataLayer.push(gtmInfo);
                }
            } catch (e) {
                // TODO: handle exception
                console.warn('Unable to trigger product search suggestion link gtm click event  ' + e.message);
            }
        });
    },

    triggerContentClick: function () {
        $('body').on('click', '.content-gtm', function (event) {
            try {
                var gtmInfo = '';
                if ($(event.target).closest('.content-gtm').length) {
                    gtmInfo = $(event.target).closest('.content-gtm').attr('data-gtm-info');
                }
                
                if (gtmInfo != '' && gtmInfo != 'null') {
                    dataLayer.push(JSON.parse(gtmInfo));
                }
            } catch (e) {
                // TODO: handle exception
                console.warn('Unable to trigger content gtm click event ' + e.message);
            }
        });
    }
}