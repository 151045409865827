'use strict';

module.exports = {
    bambuserHandler: function () {
        $(document).ready(function () {
            function initIntegration() {
                var config,
                    basketPLIs = {};

                try {
                    var configEl = document.querySelector('.js_bambuser-config');
                    config = JSON.parse(configEl.dataset.config);
                } catch (e) {
                    window.console.error('Could not load bambuser player config', e);
                    return;
                }

                window.onBambuserLiveShoppingReady = player => {
                    var locale = config.locale;

                    if (locale === "fr_FR") {
                        locale = "fr-FR";
                    } else {
                        locale = "en-US";
                    }

                    player.configure({
                        locale: locale,
                        currency: config.currency,
                        buttons: {
                            checkout: player.BUTTON.MINIMIZE,
                        }
                    });

                    player.on(player.EVENT.PROVIDE_PRODUCT_DATA, event => {
                        var productIds = [];
                        event.products.forEach(({
                            ref: sku,
                            id: productId,
                            url: publicUrl // eslint-disable-line no-unused-vars
                        }) => {
                            productIds.push(sku);
                            var dataProduct = config.urls.productData;
                            dataProduct = dataProduct + "?sku=" + sku + "&id=" + productId;
                            $.ajax({
                                url: dataProduct,
                                type: 'get',
                                dataType: 'json',
                                success: function (data) {
                                    var bambuserProduct = data.product;
                                    player.updateProduct(productId, factory => factory
                                        .currency(config.currency)
                                        .locale(locale)
                                        .product(p => p
                                            .brandName(bambuserProduct.brand)
                                            .defaultVariationIndex(0)
                                            .description(bambuserProduct.description)
                                            .name(bambuserProduct.name)
                                            .sku(bambuserProduct.id)
                                            .variations(v => bambuserProduct.variations.map(variation =>
                                                v()
                                                    .attributes(a => a
                                                        .colorName(variation.colorShadeNumber != undefined ? variation.colorName + '-' + variation.colorShadeNumber : variation.colorName)
                                                        .colorHexCode(variation.colorHexCode)
                                                    )
                                                    .imageUrls(variation.imageURLs)
                                                    .name(variation.name)
                                                    .sku(variation.sku)
                                                    .sizes(s => variation.sizes.map(size =>
                                                        s()
                                                            .name(size.name)
                                                            .inStock(size.inStock)
                                                            .price(p => p
                                                                .currency(size.price.currency)
                                                                .current(size.price.current)
                                                            )
                                                            .sku(size.sku)
                                                    ))
                                            ))
                                        )
                                    );
                                },
                                error: function (err) {
                                    console.log("error", err);
                                }
                            });
                        });
                        var datalistProductGtmUrl = $(".js_bambuser-tracking").attr("data-url");
                        datalistProductGtmUrl = datalistProductGtmUrl + "?products=" + JSON.stringify(productIds);

                        $.ajax({
                            url: datalistProductGtmUrl,
                            type: 'get',
                            dataType: 'json',
                            success: function (data) {
                                dataLayer.push(data.productListGtm);
                            },
                            error: function (err) {
                                console.error("Error on GTM", err)
                            }
                        });
                    });

                    player.on(player.EVENT.ADD_TO_CART, (addedItem, callback) => {
                        var addToCartUrl = config.urls.addToCart;
                        var form = {
                            pid: addedItem.sku,
                            quantity: addedItem.quantity || 1
                        }

                        $.ajax({
                            url: addToCartUrl,
                            method: 'POST',
                            data: form,
                            success: function (data) {
                                var quantityTotal = data.quantityTotal;
                                if (!$('div[id*="livecommerce-surf"] > iframe').length && $('.minicart').length) {
                                    window.$('.minicart').trigger('count:update', {
                                        quantityTotal
                                    });
                                } else {
                                    var cartquantity = $('div[id*="livecommerce-surf"] > iframe')[0].contentWindow.document.getElementById('cartquantity');
                                    if ($.isNumeric(quantityTotal) && cartquantity) {
                                        $('div[id*="livecommerce-surf"] > iframe')[0].contentWindow.document.getElementById('cartquantity').innerHTML = quantityTotal.toString();
                                    }
                                }
                                basketPLIs[addedItem.sku] = data.pliUUID;
                                callback(true);
                            },
                            error: function () {
                                callback(false);
                            }
                        });
                    });

                    player.on(player.EVENT.UPDATE_ITEM_IN_CART, (updatedItem, callback) => {
                        const pliUUID = basketPLIs[updatedItem.sku];

                        if (pliUUID) {
                            var updateQtyUrl = config.urls.updateQuantity;
                            var removeLineItemUrl = config.urls.removeProduct;
                            updateQtyUrl = updateQtyUrl + "?pid=" + updatedItem.sku + "&uuid=" + pliUUID + "&quantity=" + updatedItem.quantity;
                            removeLineItemUrl = removeLineItemUrl + "?pid=" + updatedItem.sku + "&uuid=" + pliUUID;
                            var updateUrl = updatedItem.quantity > 0 ? updateQtyUrl : removeLineItemUrl;

                            window
                                .fetch(updateUrl, {
                                    method: 'GET'
                                })
                                .then(response => response.json())
                                .then((responseJson) => {
                                    if (responseJson.errorMessage) {
                                        console.error("Error Message Generated by the Controller: ", responseJson.errorMessage);
                                        callback(false);
                                    }
                                    console.log('responseJson: ', responseJson);
                                    var quantityTotal = 0;
                                    if (responseJson && responseJson.numItems) {
                                        quantityTotal = responseJson.numItems;
                                    }
                                    else if (responseJson && responseJson.basket) {
                                        quantityTotal = responseJson.basket.numItems;
                                    }
                                    if (!$('div[id*="livecommerce-surf"] > iframe').length && $('.minicart').length) {
                                        window.$('.minicart').trigger('count:update', {
                                            quantityTotal
                                        });
                                    } else {
                                        var cartquantity = $('div[id*="livecommerce-surf"] > iframe')[0].contentWindow.document.getElementById('cartquantity');
                                        if ($.isNumeric(quantityTotal) && cartquantity) {
                                            $('div[id*="livecommerce-surf"] > iframe')[0].contentWindow.document.getElementById('cartquantity').innerHTML = quantityTotal.toString();
                                        }
                                    }
                                    if (updatedItem.previousQuantity < updatedItem.quantity) {
                                        var dataProductAddToCart = $(".js_bambuser-tracking-addtocart").attr("data-url");
                                        dataProductAddToCart = dataProductAddToCart + "?product=" + updatedItem.sku + "&quantity=" + updatedItem.quantity;

                                        $.ajax({
                                            url: dataProductAddToCart,
                                            type: 'get',
                                            dataType: 'json',
                                            success: function (data) {
                                                dataLayer.push(data.addedProductGtm);
                                            },
                                            error: function (err) {
                                                console.error("Error on GTM", err)
                                            }
                                        });
                                    }
                                    callback(true);
                                }).catch(error => { console.error("Catch Error", error); return; });
                        } else {
                            callback(false);
                        }
                    });

                    player.on(player.EVENT.CHECKOUT, () => {
                        var checkoutUrl = config.urls.checkout;
                        player.showCheckout(window.location.origin + checkoutUrl);
                    });
                };

                window.initBambuserLiveShopping = function (item) {
                    window.initBambuserLiveShopping.queue.push(item)
                };
                window.initBambuserLiveShopping.queue = [];
                var scriptNode = document.createElement('script');
                scriptNode['src'] = config.urls.embedScript;
                document.body.appendChild(scriptNode);
            }

            $(document).ready(function () {
                (function (d, t, i) {
                    window.__bfwId = d.getElementById('widget-id').getAttribute("data-config");
                    if (d.getElementById(i) && window.__bfwInit) return window.__bfwInit();
                    if (d.getElementById(i)) return;
                    var s, ss = d.getElementsByTagName(t)[0];
                    var l = d.getElementById('widget-locale').getAttribute("data-locale");
                    s = d.createElement(t);
                    s.id = i;
                    if (l == "fr_FR" || l == "en_IM") {
                        s.setAttribute("type", "text/plain");
                        s.classList.add("optanon-category-C0003");
                    }
                    if (d.getElementById('widget-scripturl').getAttribute("data-config") != "null") {
                        s.src = d.getElementById('widget-scripturl').getAttribute("data-config");
                        ss.parentNode.insertBefore(s, ss);
                    }
                })(document, 'script', 'bambuser-liveshopping-widget');
                (function () {
                    var showIdEl = $('.js-bambuser-show-id');
                    showIdEl.each(function () {

                        var showId = $(this).attr("data-config");
                        if (!window.initBambuserLiveShopping) {
                            initIntegration();
                        }
                        document.querySelectorAll('#bambuser-' + showId).forEach(function (element) {
                            window.initBambuserLiveShopping({
                                showId: showId,
                                node: element,
                                type: 'overlay'
                            });
                        });
                    });

                    document.querySelectorAll("[id^='bambuser-']").forEach(function (element) {
                        element.addEventListener('click', function () {
                            dataLayer.push({
                                'event': 'live_streaming'
                            });
                        })
                    });

                    window.addEventListener('bambuser-liveshop-tracking-point', ({ detail }) => {
                        if (detail.event === "on-interaction") {
                            var action = detail.data.interactionType;
                            if (action === "showProduct") {
                                var dataProductClick = $(".js_bambuser-tracking-product").attr("data-url");
                                dataProductClick = dataProductClick + "?product=" + detail.data.sku;

                                $.ajax({
                                    url: dataProductClick,
                                    type: 'get',
                                    dataType: 'json',
                                    success: function (data) {
                                        dataLayer.push(data.productClickGtm);
                                        dataLayer.push(data.prodcutPageGtm);
                                    },
                                    error: function (err) {
                                        console.error("Error on GTM", err)
                                    }
                                });
                            } else if (action === "addToCart") {
                                var dataProductAddToCart = $(".js_bambuser-tracking-addtocart").attr("data-url");
                                dataProductAddToCart = dataProductAddToCart + "?product=" + detail.data.items[0].sku + "&pageorigin=pdp";

                                $.ajax({
                                    url: dataProductAddToCart,
                                    type: 'get',
                                    dataType: 'json',
                                    success: function (data) {
                                        dataLayer.push(data.addedProductGtm);
                                    },
                                    error: function (err) {
                                        console.error("Error on GTM", err)
                                    }
                                });
                            } else if (action === "checkout") {
                                var dataCartUrl = $(".js_bambuser-tracking-cart").attr("data-url");

                                $.ajax({
                                    url: dataCartUrl,
                                    type: 'get',
                                    dataType: 'json',
                                    success: function (data) {
                                        dataLayer.push(data.checkoutCartData);
                                    },
                                    error: function (err) {
                                        console.error("Error on GTM", err)
                                    }
                                });
                            }
                        }
                    });
                })();
            });
        });
    }
}
