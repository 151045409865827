'use strict';

$(document).ready(function () {
    // Countdown JS functionality

    // Helper function
    function calculate(x, v) {
        return Math.floor(x / v);
    }
    //
    function setCountDown(element) {
        const timestampFromBM = element.attr("data-timestamp");
        let timestamp = Math.abs(Date.now() - timestampFromBM) / 1000;
    
        setInterval(function() {
            timestamp--;
    
            if ((Date.now() - timestampFromBM) / 1000 > 0) {
                element.html('');
                return;
            }
    
            let days    = calculate(timestamp, 24 * 60 * 60),           // days
                hours   = calculate(timestamp,      60 * 60) % 24,      // hours
                minutes = calculate(timestamp,           60) % 60,      // minutes
                seconds = calculate(timestamp,            1) % 60,    // seconds
                labelday = $('#days').val(),
                labelhours = $('#hours').val(),
                labelminutes = $('#minutes').val(),
                labelseconds = $('#seconds').val();
    
            element.html(days + labelday + hours + labelhours + minutes + labelminutes + seconds + labelseconds);
    
        }, 1000);
    }
    
    setCountDown($('#countdownContainer'));
    setCountDown($('.countdownTopRotator'));
   

})
