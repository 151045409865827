var base = require('../product/base');
var util = require('../components/util');

module.exports = function () {
    base.basketQuantityChange();
    base.basketRemoveProduct();
}

$(document).ready(function () {
    // Duplicate mini cart in sticky header
    var miniCartHTML = $('#shopping-cart .minicart').html()
    $('.ico-basket-mobile .minicart').html(miniCartHTML);

    function quantitySelector() {
        $('.min-cart-content .quantity-button').on('click', function () {
            var oldValue, maxValue, newVal;
            var pid = ($(this).attr("data-pid"));
            var traductionText = $(this).parent('.pdp-qty-selector-content').find('#dynamic_update').data('text');
            $.spinner().start();
        
            if (!pid){
                oldValue = $(".min-cart-content #quantity option:selected").text();
                maxValue = parseInt($('.min-cart-content #quantity option:last').val());

            
                if (this.id == "quantity-plus") {    
                    newVal = parseFloat(oldValue) + 1;
                    $(".min-cart-content #quantity option[value='"+newVal+"']").prop('selected', true);
                    $(".min-cart-content #quantity option[value='"+newVal+"']").text(newVal);
                    $('.min-cart-content #quantity-minus').prop('disabled', false);
                    if (oldValue >= maxValue - 1) {
                        $('.min-cart-content #quantity-plus').prop('disabled', true);
                    }
                } else {
                    if (oldValue > 0) {
                        newVal = parseFloat(oldValue) - 1;
                        $(".min-cart-content #quantity option[value='"+newVal+"']").prop('selected', true);
                        $(".min-cart-content #quantity option[value='"+newVal+"']").text(newVal);
                        $('.min-cart-content #quantity-plus').prop('disabled', false);
                        if (oldValue <= 2) {
                            $('.min-cart-content #quantity-minus').prop('disabled', true);
                        }
                    } else {
                        newVal = 0;
                    }
                }
                $(this).parent('.pdp-qty-selector-content').find('#dynamic_update').text(traductionText + ' ' + newVal);
            } else {
                pid = ($(this).attr("data-pid"));
                oldValue = parseInt($('.min-cart-content .quantity[data-pid='+pid+'] option:selected').text());
                maxValue = parseInt($('.min-cart-content .quantity[data-pid='+pid+'] option:last').val());

                if (this.id === 'quantity-plus') {
                    newVal = parseFloat(oldValue) + 1;
                    $(".quantity[data-pid="+pid+"] option[value="+newVal+"]").prop('selected', true);
                    $(".quantity[data-pid="+pid+"] option[value="+newVal+"]").val(newVal).change();
                    $('.min-cart-content #quantity-minus[data-pid='+pid+']').prop('disabled', false);
                    if (oldValue >= maxValue) {
                        $('.min-cart-content #quantity-plus[data-pid='+pid+']').prop('disabled', true);
                        $.spinner().stop();
                    }
                } else {
                    if (oldValue > 0) {
                        newVal = parseFloat(oldValue) - 1;
                        $(".quantity[data-pid="+pid+"] option[value="+newVal+"]").prop('selected', true);
                        $(".quantity[data-pid="+pid+"] option[value="+newVal+"]").val(newVal).change();
                        $('.min-cart-content #quantity-plus[data-pid='+pid+']').prop('disabled', false);
                        if (oldValue <= 2) {
                            $('.min-cart-content #quantity-minus[data-pid='+pid+']').prop('disabled', true);
                            $.spinner().stop();
                        }
                    } else {
                        newVal = 0;
                    }
                }
                $(this).parent('.pdp-qty-selector-content').find('#dynamic_update').text(traductionText + ' ' + newVal);
            }
        });
    }

    document.addEventListener('keydown', function (e) {
        const miniCartClose = document.getElementById('mini-cart-close-expanded')
        const lastbuttonCart = document.querySelector('.lastbutton-cart')
        const checkoutCartButton = document.getElementById('checkout-cart-button')

        if (e.target === lastbuttonCart) {
            //if target is log on button, check for key(s) pressed.
            //if keys are "shift" and "tab":
            //Focus on password text box.
            if (e.shiftKey && e.keyCode === 9) {
                e.preventDefault()
                checkoutCartButton.focus()
            } else if (e.keyCode === 9) {
                e.preventDefault();
                miniCartClose.focus()
            }
        } else if (e.target === miniCartClose) {
            //If target is user name text box, check for keys pressed.
            //if keys are "shift" and "tab":
            //Focus on log on button.
            if (e.shiftKey && e.keyCode === 9) {
                e.preventDefault()
                lastbuttonCart.focus()
            }
        }
    });

    $('.minicart').on('count:update', function (event, count) { // eslint-disable-line no-unused-vars
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
        }
    });

    $(document).on('click', '.minicart-link-desktop, .minicart-link-mobile', function () {
        var url;
        if (util.isMobile() || $(window).width() < 992) {
            url = $('.minicart-link-mobile').data('action-url');
        } else {
            url = $('.minicart-link-desktop').data('action-url');
        }

        var count = parseInt($('.minicart .minicart-quantity').text(), 10);
        $('html, body').addClass('disable_scrolling_minicart');
        
        if (util.isMobile() || $(window).width() < 992) {
            $('.minicart-link-mobile').attr('aria-expanded', 'true');
        } else {
            $('.minicart-link-desktop').attr('aria-expanded', 'true');
        }
        if ($('.minicart .popover.show').length === 0) {
            $('.mini-cart-overlay').show();
            if (count == 0) {
                $('#cart-empty').show();
                $('.mini-cart-overlay').show();
                $.spinner().stop();
            } else {
                $('.minicart .popover').spinner().start();
                $('.mini-cart-overlay').show();
                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    quantitySelector();
                    $.spinner().stop();
            
                    if (util.isMobile() || $(window).width() < 992) {
                        $('.minicart-link-mobile').focusout();
                    } else {
                        $('.minicart-link-desktop').focusout();
                    }
                    $('#mini-cart-close-expanded').trigger('focus');
                });
            }
            $('.minicart .popover').show();
            $('.popover .mini-cart-close').focus();
            $('#search-desktop-area').removeClass('show');
        }
    });

    $(document).ready(function () {
        var dialog = $('.min-cart-content');
        var dialogElements = dialog.find(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        dialogElements.last().on('keydown', function (e) {
            if (e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();
                dialogElements.first().focus();
            }
        });

        dialogElements.first().on('keydown', function (e) {
            if (e.key === 'Tab' && e.shiftKey) {
                e.preventDefault();
                dialogElements.last().focus();
            }
        });
    });

    // hide back-to-top-button in minicart mobile device.
    $(document).on('mouseenter focusin', '.minicart', function () {
        if (util.isMobile()) {
            $('.back-to-top-button').hide();
        }
    });

    $('body').on('click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').hide();
            $('.mini-cart-overlay').hide();
        }
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
});

$(document).ready(function () {
    $(document).on('click', '.mini-cart-close, .mini-cart-overlay, #continue-shopping-button, #continue-empty-shopping-button, .mini-cart-close-button', function () {
        $('.mini-cart-overlay').hide();
        $('.minicart .popover').hide();
        $('html, body').removeClass('disable_scrolling_minicart');
        if (util.isMobile() || $(window).width() < 992) {
            $('.minicart-link-mobile').attr('aria-expanded', 'false');
        } else {
            $('.minicart-link-desktop').attr('aria-expanded', 'false');
        }
        $('.minicart-total').focus();
        $('.mini-cart-close').focusout();
    });

    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            if (util.isMobile() || $(window).width() < 992) {
                if ($(".minicart-link-mobile").first().attr('aria-expanded') === 'true') {
                    $('.mini-cart-close').trigger('click');
                }
            } else {
                if ($(".minicart-link-desktop").first().attr('aria-expanded') === 'true') {
                    $('.mini-cart-close').trigger('click');
                }
            }
        }
    });
    
    var popupElement = document.querySelector(".popover");
    if (popupElement) {
        popupElement.addEventListener('focusout', function (event) {
            if (popupElement.contains(event.relatedTarget)) {
                return;
            }
            $('#mini-cart-close-expanded').focus();
        });
    }

    $(document).on('click', '#btnShowCart', function () {
        var url = $('#btnShowCart').data('target');
        window.location.href = url;
        return false;
    });

    $(document).on('click', '#continue-shopping-button', function () {
        $('.popover-bottom').removeClass('d-block');
    });

});