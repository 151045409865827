$(document).ready(function () {
    var clicked = null;

    $('.footnote-link').click(function () {
        clicked = this;
    });

    $('.back-link').click(function (event) {
        event.preventDefault();
        var targetElement = clicked;

        if (!targetElement) {
            var backingParentId = $(this).parent().attr('id');
            var matchingLink = $(
                '.footnote-link[href="#' + backingParentId + '"]'
            );

            if (matchingLink.length > 0) {
                clicked = matchingLink;
                targetElement = clicked;
            }
        }

        if (targetElement) {
            $(targetElement).focus();
            var offsetTop = $(targetElement).offset().top - 100;
            $('html, body').animate(
                {
                    scrollTop: offsetTop,
                },
                'slow',
                function () {
                    clicked = null;
                }
            );
        }
    });
});
