require('cleave.js/dist/cleave');

function checkDate(e, type) {
    var string = $(e).val();
    var theDate = string.split("/");
    switch (theDate.length) {

        case 0:
            break;
        case 1:
            if (theDate[0].length > 1 && type !== 'jp'){
                $(e).val($(e).val() + "/");
            }
            else if (theDate[0].length == 1 && parseInt(theDate[0]) > 3 && type == 'fr'){
                $(e).val(theDate[0] + "/");
            }
            else if (theDate[0].length == 1 && parseInt(theDate[0]) > 1 && type == 'en'){
                $(e).val(theDate[0] + "/");
            }
            else if (theDate[0].length > 3 && type == 'jp'){
                $(e).val(theDate[0] + "/");
            }
            break;
        case 2:

            if (theDate[1].length > 1) {
                if (theDate[1].length > 2) {
                    $(e).val(theDate[0] + "/" + theDate[1]);
                } else
                    $(e).val($(e).val() + "/");
            } else if (theDate[1].length == 1 && parseInt(theDate[1]) > 1 && type == 'fr')
                $(e).val(theDate[0] + "/0" + theDate[1] + "/");
            else if (theDate[1].length == 1 && parseInt(theDate[1]) > 3 && type == 'en')
                $(e).val(theDate[0] + "/0" + theDate[1] + "/");
            else if (theDate[1].length == 1 && parseInt(theDate[1]) > 5 && type == 'jp')
                $(e).val(theDate[0] + "/0" + theDate[1] + "/");
            break;
        case 3:
            if (theDate[1].length > 1 && theDate[1] == 0 && theDate[2]) {
                $(e).val(theDate[0] + "/" + theDate[1] + "/" + theDate[2]);
            }
            if (theDate[1].length == 1) {
                $(e).val(theDate[0] + "/" + theDate[1] + "/" + theDate[2]);
            }
            break;
        default:
            $(e).addClass("errInput");
            break;
    }
}

function initCleaveExpirationDate() {
    applyCleave('#expirationDate', '/', [2, 2]);
}

function initCleaveZipCode() {
    applyCleave('.zip-code-jp:visible', '-', [3, 4]);
}

function initCleaveMobilePhone() {
    /* CLEAVE FOR JP PHONE */
    setCleaveJapanPhone('.js-input-mobile:visible');
    setCleaveJapanPhone('.js-input-mobile-version:visible');
    setCleaveJapanHomePhone('.js-input-home:visible');
    setCleaveJapanPhone('.js-input-mobile-billing:visible');
    setCleaveJapanHomePhone('.js-input-home-billing:visible');

    /* CLEAVE FOR US PHONE */
    setCleaveUSAPhone('.js-input-mobile-us:visible');
    setCleaveUSAPhone('.js-input-mobile-billing-us:visible');

    /* CLEAVE FOR FR PHONE */
    setCleaveFrancePhone('.js-input-mobile-fr:visible');
}

function setCleaveJapanPhone(selector) {
    applyCleave(selector, '-', [3, 4, 4]);
}

function setCleaveUSAPhone(selector) {
    applyCleave(selector, '-', [3, 3, 4]);
}

function setCleaveJapanHomePhone(selector) {
    applyCleave(selector, '-', [2, 4, 4]);
}

function setCleaveFrancePhone(selector) {
    applyCleave(selector, ' ', [2, 2, 2, 2, 2]);
}

function eachCleaveMobilePhone(selectors, delimiter, rules) {
    $(selectors).each(function(index, input) { // eslint-disable-line no-unused-vars
        new Cleave(input, { // eslint-disable-line no-undef
            delimiter: delimiter,
            blocks: rules
        });
    });
}

function applyCleave(selectors, delimiter, rules) {
    $(document).ready(function() {
        eachCleaveMobilePhone(selectors, delimiter, rules);
    });
    $(document).on('focusin', selectors, function () {
        eachCleaveMobilePhone(selectors, delimiter, rules);
    });
}

$(document).ready(function () {
    $('form .form-control-label').click(function(){
        $(this).siblings('input.form-control:first').focus();
    })
    String.prototype.sansAccent = function () {
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        var str = this;
        for (var i = 0; i < accent.length; i++) {
            str = str.replace(accent[i], noaccent[i]);
        }

        return str;
    }
    var name = $('.form-input-sansaccent').data('name')
    var name_billing = $('.form-input-sansaccent-billing').data('name')

    if (name_billing == 'lnameBilling' || name_billing == 'fnameBilling') {
        var firstname_billing = document.getElementById('form-fname-billing-input');
        var lastname_billing = document.getElementById('form-lname-billing-input');
        firstname_billing.addEventListener('input', function () {
            var value_firstname_billing = firstname_billing.value
            value_firstname_billing = value_firstname_billing.trim();
            $('.form-fname-billing').val(value_firstname_billing.sansAccent());
        })
        lastname_billing.addEventListener("input", function () {
            var value_lastname_billing = lastname_billing.value
            value_lastname_billing = value_lastname_billing.trim();
            $('.form-lname-billing').val(value_lastname_billing.sansAccent());
        })
    }
    if (name == 'lname' || name == 'fname') {

        var firstname = document.getElementById('form-fname-input');
        var lastname = document.getElementById('form-lname-input');
        firstname.addEventListener("input", function () {
            var value_firstname = firstname.value
            value_firstname = value_firstname.trim();
            $('.form-fname').val(value_firstname.sansAccent());
        })
        lastname.addEventListener("input", function () {
            var value_lastname = lastname.value
            value_lastname = value_lastname.trim();
            $('.form-lname').val(value_lastname.sansAccent());
        })
    }

});

function initCleaveBirthDay() {
    var CLEAVE_BIRTHDAY_DEFAULT = ".birthday-default";
    var CLEAVE_BIRTHDAY_JP = ".birthday-jp";

    if ($(CLEAVE_BIRTHDAY_DEFAULT).length) {
        setCleaveBirthDayDefault(CLEAVE_BIRTHDAY_DEFAULT);
    }

    if ($(CLEAVE_BIRTHDAY_JP).length) {
        setCleaveBirthDayJP(CLEAVE_BIRTHDAY_JP);
    }

    function setCleaveBirthDayDefault(input) {
        new Cleave(input, { // eslint-disable-line no-undef
            delimiter: '/',
            blocks: [2, 2, 4]
        });
    }

    function setCleaveBirthDayJP(input) {
        new Cleave(input, { // eslint-disable-line no-undef
            delimiter: '/',
            blocks: [4, 2, 2]
        });
    }
}

function formatBirthDate(input_selector) {
    var date;
    var label_birthday_form = $('#label-birthday-form').val();
    var label_invalid_years = $('#label-invalid-years').val();
    var firstError_invalid_years = $('#firstError-invalid-years').val();
    var secondError_invalid_years =  $('#secondError-invalid-years').val();
    var error_invalid_day_month = $('#error-invalid-day-month').val();
    var type = $('#' + input_selector).data('type');

    if (type)
        date = document.getElementById(input_selector);
    else
        date = document.getElementById('registration-form-birthday');
    if (date) {
        date.addEventListener("input", function (event) {
            var regEx_dateformat;
            var date_val = date.value
            if (type)
                if (event.inputType == 'insertText')
                    checkDate(date, type)
            if (type == 'fr')
                regEx_dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-](\d{4})$/;
            else if (type == 'en')
                regEx_dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-](\d{4})$/;
            else if (type == 'jp') {
                regEx_dateformat = /^(\d{4})[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;
            }
            var regs = date_val.match(regEx_dateformat)
            if (regs && type) {
                var dd, mm, yy;
                var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                if (type == 'fr') {
                    dd = regs[1];
                    mm = regs[2];
                    yy = regs[3];
                } else if (type == 'en') {
                    dd = regs[2];
                    mm = regs[1];
                    yy = regs[3];
                } else if (type == 'jp') {
                    dd = regs[3];
                    mm = regs[2];
                    yy = regs[1];
                }

                if (mm == 1 || mm > 2) {
                    if (dd > ListofDays[mm - 1]) {
                        if (type == 'jp') {
                            $(this).parents('.form-group').find('.invalid-form-date').text(error_invalid_day_month).addClass('d-block'); // Invalid format
                        }else{
                            $(this).parents('.form-group').find('.invalid-form-date').text("Invalid value day: " + dd + "in month : " + mm).addClass('d-block'); // Invalid format
                        }
                        $(this).removeClass("valid").addClass("invalid")
                        return false;
                    }
                }
                if (mm == 2) {
                    var lyear = false;
                    if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
                        lyear = true;
                    }
                    if ( ((lyear == false) && (dd >= 29)) || ((lyear == true) && (dd > 29)) ) {
                        $(this).removeClass("valid").addClass("invalid")
                        if (type == 'jp') {
                            $(this).parents('.form-group').find('.invalid-form-date').text(error_invalid_day_month).addClass('d-block'); // Invalid format
                        }else{
                            $(this).parents('.form-group').find('.invalid-form-date').text("Invalid value day: " + dd + " in month : " + mm).addClass('d-block'); // Invalid format
                        }
                        return false;
                    }
                }
                // year value between 1902 and 2019
                if (yy < 1902 || yy > (new Date()).getFullYear()) {
                    $(this).removeClass("valid").addClass("invalid")
                    var dateInvalide = new Date();
                    dateInvalide.setFullYear(dateInvalide.getFullYear() - PropertiesPreferences.MINIMUM_REGISTRATION_AGE);
                    if (type === 'jp') {
                        $(this).parents('.form-group').find('.invalid-form-date').text(firstError_invalid_years + dateInvalide.getFullYear() + secondError_invalid_years).addClass('d-block'); // Invalid format
                    } else if (type === 'fr') {
                        $(this).parents('.form-group').find('.invalid-form-date').text('Valeur non valide pour l\'année : ' + yy + ' - doit être entre 1902 et ' + dateInvalide.getFullYear()).addClass('d-block'); // Invalid format
                    } else {
                        $(this).parents('.form-group').find('.invalid-form-date').text('Invalid value for year: ' + yy + ' - must be between 1902 and ' + dateInvalide.getFullYear()).addClass('d-block');
                    }
                    return false;
                }
                $(this).parents('.form-group').find('.invalid-feedback').text('').addClass('d-none').removeClass('d-block');
                $(this).parents('.form-group').find('.invalid-form-date').text('').addClass('d-none').removeClass('d-block'); // Invalid format
            } else if (!regs) {
                $(this).parents('.form-group').find('.invalid-form-date').text(label_birthday_form).addClass('d-block').removeClass('d-none'); // Invalid format
                return false;
            } else {
                $(this).parents('.form-group').find('.invalid-feedback').text(label_birthday_form).addClass('d-block').removeClass('d-none'); // Invalid format
                return false;
            }
            if (mm && dd && yy) {
                date_val = mm + '/' + dd + '/' + yy;
            }

            var minimumAge = parseInt(PropertiesPreferences.MINIMUM_REGISTRATION_AGE) + 0.1;
            date_val = new Date(date_val);
            var to_day = new Date();
            var diffDays = Math.floor((to_day - date_val) / (1000 * 60 * 60 * 24));
            var diffYears = diffDays / 365;
            diffYears = diffYears.toFixed(3);
            var MyDateString = (yy + '-' + ('0' + (date_val.getMonth() + 1)).slice(-2)) + '-' + ('0' + date_val.getDate()).slice(-2);
            if (diffYears === "NaN") {
                $(this).parents('.form-group').find('.invalid-feedback').text(label_birthday_form).addClass('d-block').removeClass('d-none'); // Invalid format
                return false;
            } else if (diffYears < minimumAge) {
                $(this).parents('.form-group').find('.invalid-years').text(label_invalid_years).addClass('d-block').removeClass('d-none');
                $(this).parents('.form-group').find('#registration-form-birthday').addClass('is-invalid').attr('aria-invalid', 'true');
                $("#registration-form-age").val(Math.round(15));
                $(this).removeClass("valid").addClass("invalid")
                $(this).closest("form").find('.is-invalid').first().focus();
            } else {
                $(this).parents('.form-group').find('.invalid-years').text('').addClass('d-none').removeClass('d-block');
                $(this).parents('.form-group').find('#registration-form-birthday').removeClass('is-invalid').removeAttr('aria-invalid');
                $("#registration-form-age").val(Math.round(diffYears));
                $(this).removeClass("invalid").addClass("valid")
                if (type) {
                    $("#registration-form-birthday").val(MyDateString);
                }
            }
        });
    }
}

$(document).ready(function () {
    if ($('#registration-form-birthday-type-revamp').length) {
        formatBirthDate('registration-form-birthday-type-revamp');
    } else {
        formatBirthDate('registration-form-birthday-type');
    }
})

$('.showPassword').each(function () {
    var $that = $(this);

    $that.on('click', function () {
        $(this).parents('.form-group').find('input').attr('type', 'text');
        $(this).hide();
        $that.parent().find('.hidePassword').show();
    });
});

$('.hidePassword').each(function () {
    var $that = $(this);
    $that.on('click', function () {
        $(this).parents('.form-group').find('input').attr('type', 'password');
        $(this).hide();
        $that.parent().find('.showPassword').show();
    });
});

$(document).ready(function () {
    $(".toggle_password")
        .on('click keypress', function (event) {
            event.preventDefault();
            if ($('.show_hide_password input').attr("type") == "text") {
                $('.show_hide_password input').attr('type', 'password');
                $(this).attr('aria-pressed', 'false');
                $('.show_hide_password i').addClass("icon-eye-blocked").removeClass("icon-EYE");
            } else if ($('.show_hide_password input').attr("type") == "password") {
                $('.show_hide_password input').attr('type', 'text');
                $(this).attr('aria-pressed', 'true');
                $('.show_hide_password i').removeClass("icon-eye-blocked").addClass("icon-EYE");
            }
        });
});

$(document).ready(function () {
    ////////////////////
    $('#t1,  #registration-form-password, #newPassword').keyup(function () {
        var str = $('#t1,  #registration-form-password, #newPassword').val();
        var condition_password_ok = $('#condition-password-ok').val()
        var condition_password_no_ok = $('#condition-password-no-ok').val()
        var upper_text = new RegExp('[A-Z]');
        var lower_text = new RegExp('[a-z]');
        var number_check = new RegExp('[0-9]');
        var special_char = new RegExp('[!/\'^�$%&*()}{@#~?><>.,|=_+�-\]');
        var flag = 'T';

        if (str.match(upper_text)) {
            $('#d12').css("color", "black");
            $('#d12 i').removeClass("icon-CROSS");
            $('#d12 i').addClass("icon-CHECK-CIRCLE");
            $('#d12 .sr-only').text(condition_password_ok);
        } else {
            $('#d12').css("color", "black");
            $('#d12 i').addClass("icon-CROSS");
            $('#d12 i').removeClass("icon-CHECK-CIRCLE");
            $('#d12 .sr-only').text(condition_password_no_ok);
            flag = 'F';
        }

        if (str.match(lower_text)) {
            $('#d13').css("color", "black");
            $('#d13 i').removeClass("icon-CROSS");
            $('#d13 i').addClass("icon-CHECK-CIRCLE");
            $('#d13 .sr-only').text(condition_password_ok);
        } else {
            $('#d13').css("color", "black");
            $('#d13 i').addClass("icon-CROSS");
            $('#d13 i').removeClass("icon-CHECK-CIRCLE");
            $('#d13 .sr-only').text(condition_password_no_ok);
            flag = 'F';
        }

        if (str.match(special_char)) {
            $('#d14').css("color", "black");
            $('#d14 i').removeClass("icon-CROSS");
            $('#d14 i').addClass("icon-CHECK-CIRCLE");
            $('#d14 .sr-only').text(condition_password_ok);
        } else {
            $('#d14').css("color", "black");
            $('#d14 i').addClass("icon-CROSS");
            $('#d14 i').removeClass("icon-CHECK-CIRCLE");
            $('#d14 .sr-only').text(condition_password_no_ok);
            flag = 'F';
        }

        if (str.match(number_check)) {
            $('#d15').css("color", "black");
            $('#d15 i').removeClass("icon-CROSS");
            $('#d15 i').addClass("icon-CHECK-CIRCLE");
            $('#d15 .sr-only').text(condition_password_ok);
        } else {
            $('#d15').css("color", "black");
            $('#d15 i').addClass("icon-CROSS");
            $('#d15 i').removeClass("icon-CHECK-CIRCLE");
            $('#d15 .sr-only').text(condition_password_no_ok);
            flag = 'F';
        }

        if (str.length > 7) {

            $('#d16').css("color", "black");
            $('#d16 i').removeClass("icon-CROSS");
            $('#d16 i').addClass("icon-CHECK-CIRCLE");
            $('#d16 .sr-only').text(condition_password_ok);

        } else {
            $('#d16').css("color", "black");
            $('#d16 i').addClass("icon-CROSS");
            $('#d16 i').removeClass("icon-CHECK-CIRCLE");
            $('#d16 .sr-only').text(condition_password_no_ok);
            flag = 'F';
        }

        if (flag == 'T') {
            $("#d1").fadeOut();
            $('#display_box').css("color", "green");
            $('#display_box').html("<span class='glyphicon glyphicon-ok' aria-hidden='true'></span> " + str);
        } else {
            $("#d1").show();
            $('#display_box').css("color", "red");
            $('#display_box').html("<span class='glyphicon glyphicon-remove' aria-hidden='true'></span> " + str);
        }
    });
    ///////////////////
    $('#t1, #registration-form-password, #newPassword').blur(function () {
        $("#d1, #d-condition-password").fadeOut();
    });
    ///////////
    $('#t1, #registration-form-password, #newPassword').focus(function () {
        $("#d1, #d-condition-password").show();
    });
    ////////////

})

/// block Breadcrumbs in chechout
$(document).ready(function () {
    $('.blockBreadcrumbs-checkoutLogin-cart .receipt .block-shopping-bag').addClass('active').attr('aria-current', 'step').find('.navigation-bullet-link').attr('aria-current', 'step');
    $('.blockBreadcrumbs-checkoutLogin-cart .receipt .block-shipping').removeClass('active').removeAttr('aria-current').find('.navigation-bullet-link').removeAttr('aria-current');
    $('.blockBreadcrumbs-checkoutLogin-cart .receipt .block-payment').removeClass('active').removeAttr('aria-current').find('.navigation-bullet-link').removeAttr('aria-current');

    if ( $('.naviagation-bullet .block-shipping').length && $('.naviagation-bullet .block-shipping').hasClass("active") ) {
        $('.shipping-summary-centent .summary-section-shipping-method, .recap-billing').removeClass('d-block');
        $('.shipping-summary-centent .summary-section-shipping-method, .recap-billing').addClass('d-none');
    } else if ($('.naviagation-bullet .block-payment').length && $('.naviagation-bullet .block-payment').hasClass("active")) {
        $('.shipping-summary-centent .summary-section-shipping-method').addClass('d-block');
        $('.shipping-summary-centent .summary-section-shipping-method').removeClass('d-none');
    }
});

$(document).on('click', '.card-container-sticky .submit-billing', function () {
    $('.footer-content-checkout').css('margin-bottom', '0');
});

$(document).on('click', '.card-container-sticky .submit-shipping', function () {
    $('.footer-content-checkout').css('margin-bottom', '0');
});

module.exports = {
    initCleaveMobilePhone: initCleaveMobilePhone,
    initCleaveBirthDay: initCleaveBirthDay,
    initCleaveExpirationDate: initCleaveExpirationDate,
    initCleaveZipCode: initCleaveZipCode
}